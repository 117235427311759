import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import ViewContainer from '../elements/ViewContainer';
import Icon from '../elements/Icon';
import { sendGetRequest } from '../scripts/Requests';
import PapersDisplay from '../components/PapersDisplay';
import { useUserContext } from '../UserContext';

const BookmarksView = ({ isActive}) => {
    const { userId } = useUserContext();
    const [papers, setPapers] = useState([]);

    useEffect(() => {
        isActive && fetchBookmarks();
    }, [userId, isActive]);

    const fetchBookmarks = async () => {
        if (!userId) return;    
        const [data, success, error] = await sendGetRequest('/backend/bookmarks', 'Get Bookmarks', { user_id: userId });
        if (success) {
            setPapers(data);
        } else {
            toast.error(error);
        }
    };


    return (
        <ViewContainer
            title="Bookmarks"
            icon="bookmark"
        >
            {papers.length === 0 ? (
                <p>
                    You haven't bookmarked any papers yet.
                    <br />
                        Start bookmarking papers to see them here! <Icon icon="rocket" className="text-accent/80 text-lg ml-2" style="regular" />
                </p>
            ) : (
                <div className="flex">
                    <PapersDisplay 
                        papers={papers} 
                        showTopicsMenu={false}
                        listItemParams={{
                            showScore: true,
                        }}
                        paperDetailsParams={{
                            showButtons: false
                        }}
                    />
                </div>
            )}
        </ViewContainer>
    );
};

export default BookmarksView;