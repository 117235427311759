import React, { useState, useEffect } from 'react';
import { UserButton } from '@clerk/clerk-react';
import { loadStripe } from '@stripe/stripe-js';
import {toast} from 'react-toastify';
import { motion } from 'framer-motion';

import sendPostRequest, {sendGetRequest,} from '../scripts/Requests';
import { UsageLimitsFree, UsageLimitsPro } from '../scripts/UsageLimits';
import { PrimaryButton, SecondaryButton } from '../elements/Buttons';
import Icon from '../elements/Icon';
import { useUserContext } from '../UserContext';



const API_ENV = process.env.NODE_ENV === 'production' ? 'production' : 'development';

let MONTHLY_PRICE_ID;
let YEARLY_PRICE_ID;
let STRIPE_PUBLISHED_KEY;

if (API_ENV === 'production') {
  MONTHLY_PRICE_ID = 'price_1Q8uVVKE02kLx3zr48ZKAIN9';
  YEARLY_PRICE_ID = 'price_1Q8uVVKE02kLx3zr2pKvkwH9';
  STRIPE_PUBLISHED_KEY = process.env.REACT_APP_STRIPE_PUBLISHED_KEY;
} else {
  STRIPE_PUBLISHED_KEY = process.env.REACT_APP_STRIPE_TEST_PUBLISHED_KEY;
  MONTHLY_PRICE_ID = 'price_1Q6Iw2KE02kLx3zrPFoqWjIx';
  YEARLY_PRICE_ID = 'price_1Q6IwYKE02kLx3zrgVjjpZGp';
}

const stripePromise = loadStripe(STRIPE_PUBLISHED_KEY);



const features = [
  ["library", "libraries", "building-columns", UsageLimitsFree.myLibraries, UsageLimitsPro.myLibraries],
  ["papers/library", "library size", "box-open", UsageLimitsFree.maxLibrarySize, UsageLimitsPro.maxLibrarySize],
  ["Quick Searches a week", "Quick Searches", "magnifying-glass", UsageLimitsFree.quickSearches, UsageLimitsPro.quickSearches],
  ["Library Searches a week", "Library Searches", "books", UsageLimitsFree.librarySearches, UsageLimitsPro.librarySearches],
  ["Smart Sort a week", "Smart Sort", "sort", UsageLimitsFree.smartSort, UsageLimitsPro.smartSort],
  ["Export Recommendations", "export ecommendations", "download", UsageLimitsFree.exportRecommendations, UsageLimitsPro.exportRecommendations],
  ["Weekly Recommendations", "weekly recommendations", "robot", UsageLimitsFree.weeklyRecommendations, UsageLimitsPro.weeklyRecommendations],
];

/* -------------------------------------------------------------------------- */
/*                                  ELEMENTS                                  */
/* -------------------------------------------------------------------------- */

const PriceCardPricingInfo = ({ billingCycle, price, isFree }) => {
  if (isFree) {
    return (
      <>
        <p className="text-3xl font-semibold text-secondary opacity-0">$0<span className="text-sm font-normal text-black">/forever</span></p>
        <p className="text-sm text-gray-400 mb-4 opacity-0">Billed monthly</p>
      </>
    );
  }

  return (
    <>
      <p className="text-3xl font-semibold text-secondary">${(price * 1).toFixed(1)}<span className="text-sm font-normal text-black">/month</span></p>
      <p className="text-sm text-gray-400 mb-4">{billingCycle === "yearly" ? `$${(price * 12).toFixed(1)} billed annually` : "Billed monthly"}</p>
    </>
  );
}

const PriceCardButton = ({ tier, onSubscribe, loading }) => {
  if (tier === 'Free') {
    return (
      null
    )
  }

  return (
    <PrimaryButton
      onClick={onSubscribe}
      disabled={loading}
      fontWeight="font-regular"
      className="w-full"
    >
      {loading ? ( 
        <Icon icon="spinner" className="animate-spin mr-2" style="solid" />
      ) : (
        <Icon icon="rocket" className="mr-2" style="solid" />
      )}
      {loading ? 'Processing...' : `Start Free Trial`}
    </PrimaryButton>
  );
};


const PriceCardFeature = ({tier, icon, feature, limit}) => {
  const isFree = tier === 'Free';

  const getLimitText = () => {
    if (limit === true) {
      return "";
    } else if (limit === Infinity) {
      return "Unlimited ";
    } else {
      if ([true, false].includes(limit)) {
        return `${limit ? "" : "No"} `;
      } else {
        return `${limit} `;
      }
    }
  }

  return (
    <li key={feature} className="mb-2 flex items-center">
      <Icon 
        icon={icon} 
        className={(isFree ? "text-gray-500" :"text-accent") + " mr-2 w-8 text-center"} 
        style={isFree ? "thin" : "solid"} 
      />
      <span className="text-gray-500">
        <span className={`highlight-border ${isFree ? "" : "font-semibold"}`}>{getLimitText()}</span>
        <span className="">{feature}</span>
      </span> 
    </li>
  )
}

const PriceCard = ({ tier, features, price, billingCycle, onSubscribe, loading, isHighlighted }) => {
  return (
    <div className={`
        price-card 
        ${tier === 'Free' ? "" : "bg-white shadow-md hover:shadow-lg"}
        rounded-lg 
        p-6 
        w-full 
        lg:w-80 
        transition-all 
        duration-300  ${isHighlighted ? 'border border-accent' : ''}
      `}>
      <h4 className={`text-4xl font-bold mb-2 underline text-gray-600`}>{tier}</h4>
      <PriceCardPricingInfo billingCycle={billingCycle} price={price} isFree={tier === 'Free'} />

      <ul className="list-none mb-6 text-left">
        {features.map(([feature, icon, limit]) => (
          <PriceCardFeature 
            key={feature} 
            tier={tier} 
            icon={icon} 
            feature={feature} 
            limit={limit}
          />
        ))}
      </ul>

      <PriceCardButton tier={tier} onSubscribe={onSubscribe} loading={loading} />
    </div>
  );
};



const SubscriptionCards = ({ features, onSubscribe, loading, prices }) => {
  const [billingCycle, setBillingCycle] = useState('monthly');

  const freeFeatures = features.map(([featureFree, __, icon, limitFree, _]) => [featureFree, icon, limitFree]);
  const proFeatures = features.map(([__, featurePro, icon, _, limitPro]) => [featurePro, icon, limitPro]);

  const BillingCycleToggle = ({ billingCycle, setBillingCycle, hide=false, className="" }) => {
    const billingCycleButtonStyle = "px-2 w-24 border border-primary-dark";
    const activeButtonStyle = "bg-primary-dark text-white";
    const inactiveButtonStyle = "bg-white";

    return (
      <div className={`${className} ${hide ? "opacity-0 cursor-default" : ""}`}>
        <div className="flex justify-center">
          <button
            onClick={() => setBillingCycle && setBillingCycle('monthly')}
            className={`rounded-l ${billingCycleButtonStyle} ${billingCycle === 'monthly' ? activeButtonStyle : inactiveButtonStyle} ${hide ? "cursor-default" : ""}`}
          >
            Monthly
          </button>
          <button
            onClick={() => setBillingCycle && setBillingCycle('yearly')}
            className={`rounded-r ${billingCycleButtonStyle} ${billingCycle === 'yearly' ? activeButtonStyle : inactiveButtonStyle} ${hide ? "cursor-default" : ""}`}
          >
            Yearly
          </button>
        </div>

        <p className="text-sm text-primary-dark mb-4 mt-1 text-center">
          Get a 20% discount when you subscribe yearly!
        </p>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center">
      

      <div className="flex flex-col lg:flex-row gap-2 justify-center">
        <div className="flex flex-col items-center">
        <BillingCycleToggle billingCycle={billingCycle} setBillingCycle={null} hide={true} className="hidden lg:block"/>
        <PriceCard
          tier="Free"
          features={freeFeatures}
          price={0}
          billingCycle={billingCycle}
          />
        </div>
        
        <div className="flex flex-col items-center w-10/12 lg:w-1/2 mx-auto">
          <BillingCycleToggle billingCycle={billingCycle} setBillingCycle={setBillingCycle} />
          <PriceCard
            tier="Pro"
            features={proFeatures}
            price={billingCycle === 'monthly' ? prices[MONTHLY_PRICE_ID] : (prices[YEARLY_PRICE_ID] / 12).toFixed(2)}
            billingCycle={billingCycle}
            onSubscribe={() => onSubscribe(billingCycle === 'monthly' ? MONTHLY_PRICE_ID : YEARLY_PRICE_ID)}
            loading={loading}
            isHighlighted={true}
          />
          <p className="text-sm text-primary-dark mb-4 mt-1 text-center mt-2 lg:w-2/3 mx-auto">
            Start using LitSearch Pro now with 14 days free trial. No credit card required and you can cancel anytime.
          </p>
        </div>
        
      </div>
    </div>
  );
};

const AccountInfo = ({ user }) => {
  return (
    <div className="text-center bg-white rounded-lg p-8 border border-gray-300 w-11/12 lg:w-fit mx-auto">
      <p className="text-xl font-bold text-secondary mb-4">Account Info</p>
      <p>We haven't implemented this yet. It's on our roadmap!</p>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                             SUBSCRIPTION STATUS                            */
/* -------------------------------------------------------------------------- */


const SubscriptionStatus = ({ status, customerId, features, trialEnd, prices }) => {

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  if (status === 'active') {
    return (
      <motion.div 
        className="text-center bg-white rounded-lg p-8 border border-gray-300 w-fit mx-auto"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h3 className="text-2xl font-bold text-secondary mb-4" variants={itemVariants}>
          <span className="highlight-border">You're a LitSearch Pro subscriber!</span>
        </motion.h3>
        <motion.p className="mb-4" variants={itemVariants}>
          <span className="highlight-border">Thank you for supporting LitSearch!</span> We hope you're enjoying all the awesome features of your Pro account.
        </motion.p>
        <motion.div variants={itemVariants}>
          <SecondaryButton
            onClick={() => window.open("https://billing.stripe.com/p/login/test_9AQbMffGj7E31Ta4gg", '_blank')}
          >
            Manage Your Subscription
          </SecondaryButton>
        </motion.div>
      </motion.div>
    );
  } else if (status === 'inactive') {
    return (
      <motion.div 
        className="text-center bg-white rounded-lg p-8 border border-gray-300"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h3 className="text-2xl font-bold text-accent mb-4" variants={itemVariants}>Your Subscription Has Expired</motion.h3>
        <motion.p className="mb-4" variants={itemVariants}>
          <span className="highlight-border">Renew now</span> to regain access to all LitSearch Pro features:
        </motion.p>

        <motion.div className="my-8" variants={itemVariants}>
          <SubscriptionCards 
            features={features}
            onSubscribe={(priceId) => window.open("https://billing.stripe.com/p/login/test_9AQbMffGj7E31Ta4gg", '_blank')}
            loading={false}
            prices={prices}
          />
        </motion.div>
      </motion.div>
    );
  } else if (status === 'trialing') {
    const trialEndDate = new Date(trialEnd * 1000).toLocaleDateString();
    return (
      <motion.div 
        className="text-center bg-white rounded-lg p-8 border border-gray-300 w-fit mx-auto"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h3 className="text-2xl font-bold text-secondary mb-4" variants={itemVariants}>
          <span className="highlight-border">You're on a LitSearch Pro trial!</span>
        </motion.h3>
        <motion.p className="mb-4" variants={itemVariants}>Enjoy all the awesome features of your Pro account for free until <span className="font-semibold">{trialEndDate}</span></motion.p>
        <motion.p className="mb-4" variants={itemVariants}>Your subscription <span className="highlight-border">will automatically continue</span> after the trial period.<br/>
        You can cancel your subscription anytime.</motion.p>
        <motion.div variants={itemVariants}>
          <SecondaryButton
            onClick={() => window.open("https://billing.stripe.com/p/login/test_9AQbMffGj7E31Ta4gg", '_blank')}
          >
            Manage Your Subscription
          </SecondaryButton>
        </motion.div>
      </motion.div>
    );
  }
  return null;
};

const NotSubscribed = ({ onSubscribe, features, user, prices }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubscribe = async (priceId) => {
    setLoading(true);
    setError(null);

    try {
      const [response, success] = await sendPostRequest(`/backend/create-checkout-session`, 
        {
          priceId: priceId,
          userId: user.id,
        }
      );

      if (!success) {
        setError(response.error);
        toast.error("Something went wrong. Please try again.");
        return;
      }

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: response.sessionId });

      if (error) {
        setError(error.message);      
        toast.error("Something went wrong. Please try again.");
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <motion.div 
      className="text-center"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h3 className="text-2xl font-bold mb-4" variants={itemVariants}>
        <Icon icon="fire" className="text-accent mr-2" style="solid" />
        Upgrade to LitSearch Pro
      </motion.h3>
      <motion.p className="mb-4" variants={itemVariants}>
        You currently have a Free account.<br/>
        Unlock all features and <span className="highlight-border">supercharge your research</span> with a Pro subscription!
        </motion.p>
      
      <motion.div className="mt-12" 
        variants={itemVariants}
      >
        <SubscriptionCards 
          features={features}
          onSubscribe={handleSubscribe}
          loading={loading}
          prices={prices}
        />
      </motion.div>
       
      {error && <motion.p className="text-red-500 mb-4" variants={itemVariants}>{error}</motion.p>}
    </motion.div>
  );
};





/* -------------------------------------------------------------------------- */
/*                                  MAIN VIEW                                 */
/* -------------------------------------------------------------------------- */
const AccountView = ({  }) => {
  const { user, subscriptionStatus, trialEnd, customerId } = useUserContext();
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [showSubscribeForm, setShowSubscribeForm] = useState(false);


  const handleSubscribe = () => {
    setShowSubscribeForm(true);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const [prices, setPrices] = useState({});

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const [response, success] = await sendGetRequest(`/backend/get-prices`);
        if (!success) {
          throw new Error('Error fetching prices');
        }
        const data = await response;
        setPrices(data);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    fetchPrices();
  }, []);

  return (
    <motion.div 
      className="account-view bg-primary min-h-screen py-0 px-4 max-w-7xl mx-auto select-none"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* USER INFO */}
      <motion.div 
        className="flex flex-col items-center lg:items-start md:justify-between"
        variants={itemVariants}
      >
        <h2 className="text-3xl font-bold text-secondary mb-2 flex gap-4 underline flex-row md:text-center">
          <UserButton 
            appearance={{
              elements: {
                userButtonAvatarBox: `w-16 h-16 rounded-full border border-secondary`,
              },
            }}
          />
          <span className="md:mt-2">Hi, {user.firstName || 'there'}</span>
        </h2>
        <p className="text-sm text-gray-400 mb-4 lg:ml-[100px] lg:-mt-4 md:text-center lg:text-left md:ml-0 md:mt-2">
          To <span className="font-semibold">log out</span>, click on your user picture.<br/>
        </p>
      </motion.div>

      {/* SUBSCRIPTION STATUS */}
      <motion.div 
        className="subscription-container mt-4"
        variants={itemVariants}
      >
        {subscriptionStatus === 'active' || subscriptionStatus === 'inactive' || subscriptionStatus === 'trialing' ? (
          <SubscriptionStatus 
            status={subscriptionStatus} 
            customerId={customerId} 
            features={features}
            trialEnd={trialEnd}
            prices={prices}
          />
        ) : (
          <NotSubscribed onSubscribe={handleSubscribe} features={features} user={user} prices={prices}/>
        )}

        {showSubscribeForm && subscriptionStatus === 'no_subscription' && (
          <motion.div 
            className="mt-8"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            <h4 className="text-xl font-bold mb-4">Subscribe to LitSearch Pro</h4>
            <div className="flex justify-center mb-4">
              <button
                onClick={() => setBillingCycle('monthly')}
                className={`mr-2 px-4 py-2 rounded ${
                  billingCycle === 'monthly' ? 'bg-accent text-white' : 'bg-gray-200'
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setBillingCycle('yearly')}
                className={`px-4 py-2 rounded ${
                  billingCycle === 'yearly' ? 'bg-accent text-white' : 'bg-gray-200'
                }`}
              >
                Yearly
              </button>
            </div>
          </motion.div>
        )}
      </motion.div>
{/* 
      <motion.div 
        className="account-info mt-4"
        variants={itemVariants}
      >
        <AccountInfo user={user} />
      </motion.div> */}
    </motion.div>
  );
};

export default AccountView;