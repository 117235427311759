import "./instrument";
import React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import * as Sentry from '@sentry/react';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn, useUser } from '@clerk/clerk-react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './UserContext';

import MainApp from './pages/MainApp';
import Landing from './pages/Landing';
import HeadTags from './components/HeadTags';
import SignupPage from './pages/Signup';
import PlaceholderPage from './pages/PlaceholderPage';


/* --------------------------------- set up --------------------------------- */
posthog.init('phc_Khc7dQXuRFS11YMU6hyCzV6qYpXP9YRQPQIJ03fmVZw',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  
  if (!user) {
    return <RedirectToSignIn />;
  }
  
  return children;
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- routes --------------------------------- */
const App = () => {
  return (
    <>
    <HeadTags/>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <UserProvider>
        <PostHogProvider client={posthog}>
          <ToastContainer />

          <Router>
            <Routes>
              <Route path="/landing" element={
                <>
                  <SignedIn>
                    <ProtectedRoute>
                      <Navigate to="/app" replace />
                    </ProtectedRoute>
                  </SignedIn>
                  <SignedOut>
                    <Landing />
                  </SignedOut>
                </>
              } />

              <Route path="/signup" element={
                <>
                  <SignedIn>
                    <ProtectedRoute>
                      <Navigate to="/app" replace />
                    </ProtectedRoute>
                  </SignedIn>
                  <SignedOut>
                    <SignupPage />
                  </SignedOut>
                </>
              } />

              <Route
                path="/app"
                element={
                  <SignedIn>
                    <ProtectedRoute>
                      <MainApp />
                    </ProtectedRoute>
                  </SignedIn>
                }
              />

              <Route path="/" element={
                <>
                  <SignedIn>
                    <ProtectedRoute>
                      <Navigate to="/app" replace />
                    </ProtectedRoute>
                  </SignedIn>
                  <SignedOut>
                    <Navigate to="/landing" replace />
                  </SignedOut>
                </>
              } />

              <Route path="/help" element={<PlaceholderPage />} />
              <Route path="/about" element={<PlaceholderPage />} />
            </Routes>
          </Router>
        </PostHogProvider>
      </UserProvider>
    </ClerkProvider>
    </>
  );
}

export default Sentry.withProfiler(App);