import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import LandingNavbar from '../components/LandingNavbar';
import QuickSearchBanner from '../components/QuickSearchBanner';
import PapersDisplay from '../components/PapersDisplay';
import Icon from '../elements/Icon';


const isMobile = window.innerWidth < 768;

const Landing = () => {
    const [papers, setPapers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [animatePlaceholders, setAnimatePlaceholders] = useState(true);

    const [isSearchCompleted, setIsSearchCompleted] = useState(false);

    // Animate placeholders every 5s before a search is done
    useEffect(() => {
        let interval;
        if (papers.length === 0 && !isLoading) {
            interval = setInterval(() => {
                setAnimatePlaceholders(prevAnimatePlaceholders => !prevAnimatePlaceholders);
            }, 5000);
        } else {
            clearInterval(interval);
            setAnimatePlaceholders(false);
        }


        return () => clearInterval(interval);
    }, [papers, isSearchCompleted]);

    return (
        <div className={`
                landing-page min-h-screen flex flex-col 
                pb-10 
                ${papers.length === 0 ? 'overflow-hidden bg-gradient-to-b from-white to-secondary/10' : 'bg-primary'}
              `}>
            <LandingNavbar hideLinks={true} />
            
            <div className="flex-grow flex flex-col items-center justify-start pt-10 relative">

                {/* Description and search */}
                <motion.div 
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="w-full max-w-7xl mb-5 flex flex-col lg:flex-row-reverse"
                >
                    {/* Description text and images */}
                    <div className="w-10/12 mx-auto lg:mx-0 lg:w-[25%] flex flex-col items-center">
                        <h3 className="text-2xl font-bold text-accent text-center mb-3">
                            Supercharge Your Research
                        </h3>

                        {/* before/after images */}

                        <div className="flex flex-row items-center space-x-4  mb-2 ">
                            <div className="text-center">
                                <p className="mb-1 text-sm font-semibold text-gray-500">Without LitSearch</p>
                                <img src="landing_before.png" alt="Before LitSearch" 
                                    className="w-40 h-40 bg-white object-cover rounded border border-gray-400" />
                                
                            </div>
                            <div className="text-center">
                                <p className="mb-1 text-sm font-semibold text-gray-500">With LitSearch</p>
                                <img src="landing_after.png" alt="After LitSearch" 
                                      className="w-40 h-40 bg-white object-cover rounded border border-gray-400" />
                            </div>
                        </div>



                        <ul className="space-y-2 pl-2 my-2 border-y border-gray-300 py-2">
                            {[

                                { icon: "memo", text: "Personalized recommendations based on your reading history", hint: "Don't spend hours sifting through piles of irrelevant papers" },
                                { icon: "robot", text: "AI-powered smart relevance sorting", hint: "Find what you need the most, first" },
                                { icon: "books", text: "Organize with custom libraries", hint: "Organize your literature discovery by topic to stay up to date on your interests" },
                                { icon: "calendar", text: "Automatic periodic recommendations", hint: "Let us take care of the rest" },
                            ].map((item, index) => (
                                <li key={index} className="flex items-start">
                                    <span className="w-[20]px">
                                    <Icon icon={item.icon} className="text-secondary mr-2 " />
                                    </span>
                                    <div >
                                        <p className="text-gray-700">{item.text}</p>
                                        <p className="text-gray-400 text-xs">{item.hint}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <div className=" text-gray-400 text-sm">
                            Sign up and start using LitSearch <span className="border-b-2 border-secondary/70">for free!</span> 
                        </div>
                    </div>

                    {/* title and search bar */}
                    <div className="w-11/12 mx-auto text-center mt-20 mb-12 lg:mt-0 lg:text-left lg:mx-0 lg:w-[75%]  lg:mt-10">
                        <motion.div
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                            className="text-4xl sm:text-7xl font-bold text-accent self-start select-none w-full lg:w-1/2"
                        >

                            {isMobile ? "Try LitSearch now!" : "Find your next best idea"}
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                            className="sm:text-2xl text-gray-500 mb-2 mt-4 self-start ml-8 select-none "
                        > 
                        <Icon icon="microscope" style="light" className="mr-2 mb-4" />
                            Automate your literature discovery
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="w-full max-w-4xl mb-0"
                        >
                            <QuickSearchBanner 
                                papers={papers} 
                                setPapers={setPapers} 
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                setIsSearchCompleted={setIsSearchCompleted}
                                hideStatusUpdates={false}
                                numResultsDefault={100}
                                origin='landing'
                            />
                            <AnimatePresence mode="wait">
                                {!isLoading ? (
                                    <motion.div 
                                        key="hints"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.3 }}
                                        className="flex flex-col gap-1 mt-2 w-full lg:w-2/3 mx-auto select-none"
                                    >
                                        <p className="text-gray-500 ml-8 text-sm">
                                            <Icon icon="search" style="light" className="mr-1 text-secondary" />
                                            <span className="font-semibold text-secondary">Search:</span> describe the topic you're interested in, we'll find the best papers for you.
                                        </p>
                                        <p className="text-gray-500 ml-8 text-sm opacity-70">
                                            <Icon icon="lightbulb" style="light" className="mr-1 text-secondary" />
                                            <span className="text-secondary">Hint:</span> be specific with your search prompt to get better results.
                                        </p>
                                        <p className="text-gray-500 ml-8 text-sm opacity-70">
                                            <Icon icon="fire" style="light" className="mr-1 text-accent" />
                                            <span className="text-secondary">Hint:</span> you can do more targeted <span className="highlight px-1"><Icon icon="building-columns" style="light" className="mr-1 text-secondary" />library searches</span> by creating an account
                                        </p>
                                    </motion.div>
                                ) : (
                                    <motion.p 
                                        key="loading"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.3 }}
                                        className="text-gray-500 ml-20"
                                    >
                                        <Icon icon="arrow-down" style="light" className="mr-1 text-secondary animate-bounce" />
                                        Your results will appear below....
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </motion.div>
                    </div>
                </motion.div>

                {/* Papers display */}
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.75 }}
                    className={`w-full ${papers.length === 0 || isLoading ? 'mt-12' : 'mt-8'}`}
                >
                    <PapersDisplay papers={papers} isLoading={isLoading || animatePlaceholders} showSmartSort={false} />
                </motion.div>
            </div>
        </div>
    );
};

export default Landing;
