import React from 'react';
import Icon from '../elements/Icon';

const PlaceholderPage = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-primary">
            <h1 className="text-4xl font-bold">Coming Soon</h1>
            <p className="text-lg text-gray-600">This page is under construction.
                <Icon icon="rocket" className="ml-2 text-accent" />
            </p>
        </div>
    );

};

export default PlaceholderPage;

