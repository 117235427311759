import React from 'react';
import { motion } from 'framer-motion';


import Icon from '../elements/Icon';


const SearchSettings = ({ 
    isVisible, 
    startYear, 
    setStartYear, 
    endYear, 
    setEndYear, 
    numResults, 
    numResultsDefault = 200,
    setNumResults 
  }) => {

  const currentYear = new Date().getFullYear();

  const handleYearChange = (e, setter) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const handleNumResultsChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && parseInt(value) <= 250) {
      setNumResults(value);
    }
  };


  const textStyle = "text-sm text-gray-500"
  const inputStyle = "w-20 text-center text-sm border bg-white outline-none z-[-1]"

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: isVisible ? 1 : 0, height: isVisible ? 'auto' : 0 }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden w-full mx-auto"
    >
      <div className="flex flex-col sm:flex-row sm:justify-center gap-4 items-center max-w-3xl mx-auto">
        <div className="flex flex-row items-center justify-center gap-0 sm:mb-4 mb-0">
          <div className="flex items-center mr-2">
            <Icon icon="calendar" className="mr-2 text-gray-400" />
            <input
              type="text"
              placeholder="1900"
              value={startYear}
              onChange={(e) => handleYearChange(e, setStartYear)}
              className={`${inputStyle} rounded-l border-r-0`}
            />
            <input
              type="text"
              placeholder={currentYear.toString()}
              value={endYear}
              onChange={(e) => handleYearChange(e, setEndYear)}
              className={`${inputStyle} rounded-r`}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Icon icon="hashtag" className="mr-2 text-gray-400" />
          <input
            type="text"
            placeholder={numResultsDefault.toString()}
            value={numResults}
            onChange={handleNumResultsChange}
            className={`${inputStyle} rounded`}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default SearchSettings;
