import React from 'react';
import Icon from './Icon';
import { useUserContext } from '../UserContext';

const ViewContainer = ({ title, icon, children }) => {
  return (
    <div className="account-view bg-primary min-h-screen py-0 max-w-7xl mx-auto select-none" >

        <div className="flex flex-col gap-6">
            <h1 className="text-3xl font-bold text-secondary-dark/70 lg:text-left text-center mb-8">
                <Icon icon={icon} className="mr-4 text-accent/80 text-2xl" style="solid" />
                {title}
            </h1>
            {children}
        </div>
    </div>
  );
};


export default ViewContainer;