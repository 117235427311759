import React from 'react';
import { motion } from 'framer-motion';
import { SignUp } from '@clerk/clerk-react';


import Icon from '../elements/Icon';
import LandingNavbar from '../components/LandingNavbar';

const SignupPage = () => {
  return (
    <div className="signup-page min-h-screen min-w-screen bg-primary pb-10 select-none">
      <LandingNavbar hideLinks={true} />
      
      <div className="flex flex-col items-space-between justify-space-between pt-10 relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className=" p-8"
        >
          <h2 className="text-3xl font-bold pl-2 text-accent mb-2 flex items-center">
            <Icon icon="user-plus" className="mr-4 text-2xl text-accent" />
            Create Your Account
          </h2>
          <div className="flex flex-col lg:flex-row justify-between items-start gap-12 mt-2">
            <div className="mt-4 relative w-full lg:w-1/2">
              <p className="mb-4 lg:text-xl md:text-lg">
                Join our community and make <span className="highlight px-1">your literature discovery more efficient.</span> Get access to LitSearch's 
                <span className="font-semibold"> enhanced paper search features</span> with a <span className="highlight-border">free account</span>.
              </p>

              <div>
                <p className="mb-4 text-xl">
                  
                </p>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
                  {[
                    { icon: 'building-columns', title: "My Libraries", description: "Organize papers and launch targeted searches" },
                    { icon: 'stopwatch', title: "Automated Updates", description: "Stay ahead with personalized recommendations" },
                    { icon: 'magnifying-glass', title: "Quick Search", description: "Discover new papers at lightning speed" },
                    { icon: 'bullseye', title: "Library Search & Smart Sort", description: "Pinpoint impactful papers effortlessly" }
                  ].map((feature, index) => (
                    <motion.div 
                      key={index}
                      className="p-4 bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 group"
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <Icon icon={feature.icon} className="text-3xl text-secondary group-hover:text-accent mb-2" />
                      <h3 className="font-semibold text-lg mb-1">{feature.title}</h3>
                      <p className="text-sm text-gray-600">{feature.description}</p>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>

            <div className="relative w-full lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
              <div className="w-full max-w-md">
                <SignUp 
                  appearance={{
                    elements: {
                      formButtonPrimary: 'bg-accent hover:bg-accent-dark border-none shadow-none text-white w-full',
                      formFieldInput: 'bg-white text-secondary border-gray-300 focus:border-accent focus:ring-accent',
                      card: 'shadow-none w-full',
                      rootBox: 'w-full shadow-none',
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SignupPage;