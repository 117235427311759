import React, { useState, useEffect, useRef, useCallback } from 'react';
import TextInput from '../elements/TextInput';
import { toast } from 'react-toastify';
import { posthog } from 'posthog-js';

import socket  from '../scripts/socket';
import Icon from '../elements/Icon';
import SearchSettings from './SearchSettings';
import sendPostRequest from '../scripts/Requests';
import StatusUpdates from '../elements/StatusUpdates';
import { useUserContext } from '../UserContext';

const QuickSearchBanner = ({
        papers, 
        setPapers, 
        isLoading, 
        setIsLoading, 
        setIsSearchCompleted, 
        setShowPromotionBanner, 
        hideStatusUpdates=false,
        numResultsDefault=200,
        origin='landing'
    }) => {
    const { userId, isFreeTier } = useUserContext();
    const [showSettings, setShowSettings] = useState(false);
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [numResults, setNumResults] = useState('');
    const [statusUpdates, setStatusUpdates] = useState({});
    const [isStatusVisible, setIsStatusVisible] = useState(false);
    const papersRef = useRef(papers);
    const [currentSearchPrompt, setCurrentSearchPrompt] = useState('');
    const currentSearchPromptRef = useRef('');

    useEffect(() => {
        papersRef.current = papers;
    }, [papers]);

    useEffect(() => {
        currentSearchPromptRef.current = currentSearchPrompt;
    }, [currentSearchPrompt]);

    const onSettings = () => {
        setShowSettings(!showSettings);
    };

    const updateStatus = (icon, text, color='text-gray-400') => {
        setStatusUpdates({
            icon: icon,
            text: text,
            color: color
        });
        setIsStatusVisible(true);
      };


    // socket listeners
    useEffect(() => {
        // Add socket listeners
        socket.on('search_results', (data) => {
            setPapers(prevPapers => {
                const newPapers = data.papers.filter(newPaper => 
                    !prevPapers.some(existingPaper => existingPaper.paperId === newPaper.paperId)
                );
                return [...prevPapers, ...newPapers];
            });

            updateStatus('robot', `Looking for more papers...`, );
        });

        socket.on('search_error', (data) => {
            console.error("Search error:", data.error);
            toast.error(`Search failed: ${data.error}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setIsLoading(false); // Set loading state to false on error
            updateStatus('triangle-exclamation', "Search failed. Please try again later or contact support.", 'text-accent');
            setIsStatusVisible(true);
        });

        socket.on('queries_completed', (data) => {
            updateStatus('robot', 'Processing results', );
            setPapers(data.papers);
        });

        socket.on('sorting_completed', (data) => {
            handleSearchComplete(data.papers);
        });

        socket.on('quick_search_usage_error', (data) => {
            toast.error('Free tier users can only perform a few quick searches per week. Please upgrade to LitSearch Pro to continue.');
            updateStatus('triangle-exclamation', "You've used your weekly limit for quick searches.", 'text-accent');
            setIsLoading(false);
        });

        // Clean up listeners on component unmount
        return () => {
            socket.off('search_results');
            socket.off('search_error');
            socket.off('queries_completed');
            socket.off('sorting_completed');
            socket.off('quick_search_usage_error');
        };
    }, [setPapers, setIsLoading]);



    // handle search
    const handleSearch = async (inputValue) => {
        setStatusUpdates({});

        if (!inputValue || inputValue.trim() === '') {
            toast.warning('Please enter a search query', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        setIsLoading(true);
        setIsSearchCompleted(false);
        setShowPromotionBanner && setShowPromotionBanner(true);
        setCurrentSearchPrompt(inputValue);
        currentSearchPromptRef.current = inputValue; // Update ref immediately
        setPapers([]);
        updateStatus('robot', "Searching for the very best papers");
        setIsStatusVisible(true);


        const searchSettings = {
            start_year: parseInt(startYear) || 1900,
            end_year: parseInt(endYear) || new Date().getFullYear(),
            limit: parseInt(numResults) || numResultsDefault
        };

        posthog.capture(origin === 'landing' ? 'landing_quick_search' : 'quick_search', {
            search_query: inputValue,            
            ...searchSettings
        });

        try {
            const [response, success] = await sendPostRequest('/backend/quick-search', {
                topic: inputValue, 
                ...searchSettings,
                user_id: userId,
                is_free_tier: isFreeTier
            });
            if (!success) {
                console.error("Search failed with response:", response);
                toast.error('Search failed. Please try again.', {message: response.message});
                setIsLoading(false);
                setIsSearchCompleted(false);
            }
        } catch (error) {
            console.error("Error during search:", error);
            toast.error('Search failed. Please try again.', {message: error.message});
            setIsLoading(false);
            setIsSearchCompleted(false);
            setIsStatusVisible(true);
        }
    };
      
    // handle search complete
    const handleSearchComplete = useCallback(async (finalPapers) => {
        const prompt = currentSearchPromptRef.current;
        
        if (finalPapers.length === 0) {
            return;
        }
        if (!prompt) {
            console.error("Current search prompt is empty. This shouldn't happen.");
            return;
        }

        updateStatus('sort', "Sorting results by relevance", );
        const [response, success] = await sendPostRequest('/backend/score-by-llm', 
            {papers: finalPapers, prompt: prompt, shouldExpandPrompt: true}
        );

        if (!success) {
            console.error("Search failed with response:", response);
            toast.error('Search failed. Please try again.', {message: response?.message || 'Unknown error'});
            return;
        }
        setPapers(response.papers);
        setIsLoading(false);
        setIsSearchCompleted(true);
        updateStatus('check', "Search completed!", 'text-secondary');

        // hide after 3 seconds
        setTimeout(() => {
            setIsStatusVisible(false);
        }, 3000);
    }, [updateStatus, setPapers, setIsLoading, setIsSearchCompleted, setIsStatusVisible]);

    return (
        <div className="flex flex-col gap-2">
        <TextInput 
            placeholderTexts={[
              'Models of climate change effects on agriculture in developing countries',
              'Applications of Transformer models to time series forecasting',
              'Efficient machine learning models for wearable devices',
              'Look for animal studies on the spatial navigation and memory'
            ]}
            useTypewriter={true}
            icon={!isLoading ? 'search' : 'hourglass'}
            buttonText="Start search"
            onButtonClick={() => handleSearch(currentSearchPromptRef.current)}
            onEnterPress={() => handleSearch(currentSearchPromptRef.current)}
            onSettings={onSettings}
            onTextChange={(value) => {
                setCurrentSearchPrompt(value);
                currentSearchPromptRef.current = value; // Update ref immediately
            }}
            value={currentSearchPrompt}
            buttonProps={{
              disabled: isLoading
            }}
        >
            <div className="w-full sm:w-11/12 md:w-4/5 mx-auto">
                <h3 className="text-xl font-bold">Search instructions</h3>
                <p className="w-full mx-auto">Specify a research topic or question you're interested in. 
                    <span className="highlight"> You can be fairly specific</span> - imagine that you were describing what you're looking
                    for to a colleague.<br /> For example, if you're looking for papers on Parkinson's disease, you might want to focus your 
                    search on animal studies showing effects of a specific drug. Just let us know what you're looking for!
                </p>
                    
                <p className="w-11/12 mt-4 sm:w-full mx-auto"><Icon icon={'magnifying-glass'} className="text-secondary/80" /> After entering your query, press the button to start the search.</p>
                <p className="w-11/12 sm:w-full mx-auto"><Icon icon={'book'} className="text-secondary/80" /> Paper results will be shown below.</p>
            </div>
        </TextInput>
        {!hideStatusUpdates && <StatusUpdates {...statusUpdates} isVisible={isStatusVisible} />}
        <SearchSettings 
            isVisible={showSettings} 
            startYear={startYear} 
            setStartYear={setStartYear} 
            endYear={endYear} 
            setEndYear={setEndYear} 
            numResults={numResults} 
            numResultsDefault={numResultsDefault}
            setNumResults={setNumResults} 
        />
        </div>
    )
}

export default QuickSearchBanner;
