import React, { createContext, useState, useContext, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { checkSubscriptionStatus } from './scripts/CheckSubscription';
import posthog from 'posthog-js';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { user } = useUser();
  const [userId, setUserId] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState('free');
  const [subscriptionStatus, setSubscriptionStatus] = useState('loading');
  const [trialEnd, setTrialEnd] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [isFreeTier, setIsFreeTier] = useState(false);

  useEffect(() => {
    if (user) {
      setUserId(user.id);
      // Identify user in PostHog
      posthog.identify(user.id, {
        email: user.primaryEmailAddress.emailAddress,
        name: user.fullName,
      });
      
      const checkSubscription = async () => {
        try {
          const customer_info = await checkSubscriptionStatus(user);
          const status = customer_info.status;
          setCustomerId(customer_info.customerId);
          setSubscriptionStatus(status);
          setTrialEnd(customer_info.trialEnd);
          setSubscriptionTier(status === 'active' || status === 'trialing' ? 'pro' : 'free');
          setIsFreeTier(status === 'active' || status === 'trialing' ? false : true);
        } catch (error) {
          console.error('Error checking subscription:', error);
          setSubscriptionStatus('error');
        }
      };
      checkSubscription();
    }
  }, [user]);

  return (
    <UserContext.Provider value={{
      user,
      userId,
      subscriptionTier,
      subscriptionStatus,
      trialEnd,
      customerId,
      isFreeTier
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
