import React, { useState } from 'react';

import { IconButton } from './Buttons';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const [inputPage, setInputPage] = useState(currentPage);

	const handlePrevious = () => {
		if (currentPage > 1) {
			paginate(currentPage - 1);
            setInputPage(currentPage - 1);
		}
	};

	const handleNext = () => {
		if (currentPage < totalPages) {
			paginate(currentPage + 1);
            setInputPage(currentPage + 1);
		}
	};

	const handleInputChange = (e) => {
		setInputPage(e.target.value);
	};

	const handleInputSubmit = (e) => {
		e.preventDefault();
		const newPage = parseInt(inputPage, 10);
		if (newPage >= 1 && newPage <= totalPages) {
			paginate(newPage);
		} else {
			setInputPage(currentPage);
		}
	};

    const buttonStyle = 'border-b-2 border-transparent hover:border-secondary/80 disabled:opacity-50';

	return (
		<nav className="flex justify-center items-center space-x-4 mt-4 text-sm">
            <IconButton icon='arrow-left' onClick={handlePrevious} disabled={currentPage === 1} className={buttonStyle} />
            <IconButton icon='arrow-right' onClick={handleNext} disabled={currentPage === totalPages} className={buttonStyle} />

			<form onSubmit={handleInputSubmit} className="flex items-center">
				<input
					type="number"
					value={inputPage}
					onChange={handleInputChange}
					onBlur={() => setInputPage(currentPage)}
					className={`
                        w-8 text-center bg-transparent
                        text-right
                        outline-none
                        [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none 
                        [&::-webkit-inner-spin-button]:appearance-none 
                        [::selection]:bg-secondary
                        select-none
                        font-semibold
                        ${buttonStyle}
                    `}
				/>
				<span className="mx-1 pb-0.5">/</span>
				<span className="pb-0.5">{totalPages}</span>
			</form>
		</nav>
	);
};

const PaginatedList = ({ items, renderItem, itemsPerPage = 20 }) => {
	const [currentPage, setCurrentPage] = useState(1);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<>
			<div className='flex flex-col h-[95%] gap-1 overflow-y-auto'>
				{currentItems.map((item, index) => (
					renderItem(item, index)
				))}
			</div>

			{items.length > itemsPerPage && (
				<Pagination
					itemsPerPage={itemsPerPage}
					totalItems={items.length}
					paginate={paginate}
					currentPage={currentPage}
				/>
			)}
		</>
	);
};

export default PaginatedList;

