import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import sendPostRequest, { sendGetRequest } from '../scripts/Requests';
import { useUser } from "@clerk/clerk-react";
import { toast } from 'react-toastify';
import posthog from 'posthog-js';

import { TooltipButton } from '../elements/Buttons';
import Icon from '../elements/Icon';
import { useUserContext } from '../UserContext';

const iconButtonStyle = `
    border-b-2
    z-50
    px-2
`


const PaperDetailsControls = ({ paper, onBookmarkPaper,  isMobile, url, isBookmarked, showButtons=true, handleAddSelectedPapersToLibrary=null }) => {
    const { userId } = useUserContext();
    const handleAddToLibrary = () => {
        if (!userId) {
            toast.warning('You must be logged in to add papers to your library')
            return;
        }
        handleAddSelectedPapersToLibrary(paper)
        posthog.capture('add_paper_to_library', { paperId: paper.paperId, title: paper.title });
    }
    
    return (
        <div className={`
                    flex flex-row items-center 
                    justify-end gap-2 pr-4 mb-2 
                    ${isMobile ? 'bg-white rounded-lg px-2 py-1' : ''}
                    ${showButtons ? 'opacity-100' : 'opacity-0'}
                    
                `}>

            <TooltipButton
                  icon="circle-plus"
                  onClick={handleAddToLibrary}
                  tooltip="Add selected papers to the library"
                  className={`papers-display-controls-button ${iconButtonStyle}`}
            />

            {url && (
                <TooltipButton 
                    icon='link' 
                    onClick={() => {
                        window.open(url, '_blank');
                        posthog.capture('open_paper_url', { paperId: paper.paperId, title: paper.title });
                    }} 
                    tooltip="Open paper in new tab" 
                    className={`papers-display-controls-button ${iconButtonStyle}`}
                />
            )}

            <TooltipButton 
                icon='bookmark'
                onClick={() => {
                    onBookmarkPaper();
                    posthog.capture('toggle_bookmark', { paperId: paper.paperId, title: paper.title, action: isBookmarked ? 'remove' : 'add' });
                }} 
                tooltip={isBookmarked ? "Remove bookmark" : "Bookmark this paper"} 
                className={`
                    papers-display-controls-button 
                    ${isBookmarked ? 'bookmark rounded' : ''}
                    ${iconButtonStyle}
                `}
            />

        </div>
    )
}


const PaperDetails = ({ paper,  isMobile, showButtons=true, handleAddSelectedPapersToLibrary=null }) => {
    const { user } = useUser();
    const [isBookmarked, setIsBookmarked] = useState(false);

    useEffect(() => {
        // Check if the paper is bookmarked when the component mounts or when the paper changes
        checkBookmarkStatus();
        posthog.capture('view_paper_details', { paperId: paper.paperId, title: paper.title });
    }, [paper]);

    const checkBookmarkStatus = async () => {
        if (!user || !user.id) return;
        const [bookmarks, success, error] = await sendGetRequest('/backend/bookmarks', 'Get Bookmarks', { user_id: user.id });
        if (success) {
            setIsBookmarked(bookmarks.some(bookmark => bookmark.paperId === paper.paperId));
        } else {
            toast.error(`Error checking bookmark status: ${error}`)
            console.log('Error checking bookmark status', bookmarks, success, error)
        }
    };

    const handleBookmarkPaper = async () => {
        if (!user || !user.id) {
            toast.warning('You must be logged in to bookmark papers')
            return;
        }
        const [response, success] = await sendPostRequest('/backend/bookmark', { user_id: user.id, paper_id: paper.paperId }, 'Toggle Bookmark');
        if (success) {
            checkBookmarkStatus()
            // toast.success(`Paper ${!isBookmarked ? 'bookmarked' : 'removed from bookmarks'}`)
        } else {
            toast.error('Error bookmarking paper')
        }
    };

    const _score = paper.score ? Math.round(paper.score * 100) : null
    const scoreColor = _score ? _score > 80 ? "text-accent" : "text-color" : "text-color"

    const hasUrl = paper.url && paper.url.length > 0

    return (
        <div className="flex flex-col max-h-[100vh] group">
            <PaperDetailsControls 
                paper={paper}
                onBookmarkPaper={handleBookmarkPaper} 
                isMobile={isMobile}
                url={paper.url}
                isBookmarked={isBookmarked}
                showButtons={showButtons}
                handleAddSelectedPapersToLibrary={handleAddSelectedPapersToLibrary}
            />
        
            <motion.div
                key={paper.paperId}
                initial={{ opacity: 0, x: 25 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -25 }}
                transition={{ duration: 0.15 }}
                className="rounded-lg border bg-white overflow-y-auto no-scrollbar p-6 hover:drop-shadow-lg"
            >
                {/* Title and Authors */}
                <h2 className={`text-xl font-bold mb-4 leading-7 text-center ${hasUrl ? 'cursor-pointer hover:text-secondary' : ''} transition-colors duration-300`}
                    onClick={hasUrl ? () => {
                        window.open(paper.url, '_blank');
                        posthog.capture('open_paper_url_from_title', { paperId: paper.paperId, title: paper.title });
                    } : null}
                >
                    {paper.title}
                </h2>
                <p className="text-gray-600 mb-4 text-left">
                    <Icon icon="users" className="mr-3 text-secondary/60 text-sm" />
                    {paper.authors}
                </p>
                <p className="text-gray-500 italic mb-4 text-left">
                    <Icon icon="newspaper" className="mr-3 text-secondary/60 text-sm" />
                    {paper.journal}
                </p>

                {/* Score  */}
                <div className="flex space-x-4 mb-4 text-center flex-row justify-center items-center select-none">
                {_score  && <div className="font-semibold">
                    <Icon icon="fire" className="mr-2 text-accent" />
                    <span className={scoreColor}>{_score}</span><span className="text-sm font-light">/100</span>
                </div>}
                <div><Icon icon="chart-network" className="mr-2 text-secondary" /> <span className="font-semibold">{paper.n_citations}</span> citations</div>
                <div><Icon icon="calendar" className="mr-2 text-secondary" /> <span className="font-semibold">{paper.year}</span></div>
                </div>

                <p className="text-lg font-semibold text-gray-800 mb-2 mt-8 text-left">Abstract</p>
                <p className="text-gray-800 text-left text-sm leading-5 text-justify whitespace-pre-wrap break-words ">{paper.abstract}</p>
            </motion.div>
    </div>
)};

const PaperDetailsPlaceholder = ({ isLoading }) => {
    const componentTransition = {
        initial: { opacity: 0.5 },
        animate: { opacity: isLoading ? [1, 0.5, 1] : 1 },
        transition: isLoading ? { duration: 3, repeat: Infinity,  repeatDelay: 4 } : { duration: 0.3 }
    }

    return (
        <motion.div
            className="space-y-4 bg-white rounded-lg p-6 border"
            initial={{ scale: 0.95 }}
            animate={isLoading ? { scale: [1, 1.02, 1] } : { scale: 1 }}
            transition={isLoading ? { duration: 3, repeat: Infinity,  repeatDelay: 4 } : { duration: 0.3 }}
        >
            <motion.div className="w-3/4 h-8 bg-gray-300 rounded" {...componentTransition} />
            <motion.div className="w-1/2 h-4 bg-gray-300 rounded" {...componentTransition} />
            <div className="flex space-x-4">
                <motion.div className="w-20 h-6 bg-gray-300 rounded" {...componentTransition} />
                <motion.div className="w-24 h-6 bg-gray-300 rounded" {...componentTransition} />
                <motion.div className="w-16 h-6 bg-gray-300 rounded" {...componentTransition} />
            </div>
            <div className="space-y-2 mt-10">
                {[...Array(12)].map((_, index) => (
                    <motion.div 
                        key={index}
                        className={`w-${['11/12', 'full', '5/6', '3/4', '10/12', '4/5', '11/12', '9/10', '7/8', 'full', '5/6', '11/12'][index]} h-4 bg-gray-200 rounded ${index === 0 ? 'mt-10' : ''}`}
                        {...componentTransition}
                    />
                ))}
            </div>
        </motion.div>
    )
};

export default PaperDetails;
export { PaperDetailsPlaceholder, PaperDetailsControls }
