import React from 'react';
import { motion } from 'framer-motion';
import Icon from '../elements/Icon';


const PaperListItem = ({ 
    paper, 
    onClick,
    isSelected, 
    showScore = true, 
    onHover = 'hover:bg-secondary/5 hover:border-slate-600 hover:bg-white', 
    onSelected = 'border-secondary bg-white', 
    children 
  }) => {
  const _score = paper.score ? Math.round(paper.score * 100) : null;
  const scoreColor = _score ? _score > 80 ? "text-accent" : "text-color" : "text-color";

  return (
    <motion.div
      className={`
        paper-list-item
        flex flex-col-reverse lg:flex-row sm: cursor-pointer py-4 border border-gray-200 
        drop-shadow-sm rounded group
        px-5 lg:px-0
        w-full
        ${onHover}
        ${isSelected ? onSelected : 'bg-white'}`}
      whileHover={{ scale: 1.0 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
    >
      {showScore && (      
        <div className="w-full mx-auto lg:mx-0 lg:w-fit flex flex-row lg:flex-col justify-between items-start pl-2 lg:pr-0 mt-2 lg:mt-0">
          <div className={`score-container text-sm flex items-center pr-2 pl-3 ${_score ? '' : 'hidden'}`}>
            <Icon icon="fire" className="mr-2 text-right text-sm text-accent opacity-70 w-5 h-5 flex-shrink-0 pt-0.5" />
            <span className={`${scoreColor} text-sm`}>{_score ? _score : "0"}</span><span className="text-xs font-light">/100</span>
          </div>
          <div className="text-sm flex items-center pl-3">
            <Icon icon="chart-network" className="mr-2 text-right text-sm text-secondary opacity-70 w-5 h-5 flex-shrink-0 pt-0.5" />
            <span>{paper.n_citations}</span>
          </div>
          <div className="text-sm flex items-center pl-3">
            <Icon icon="calendar" className="mr-2 text-right text-sm text-secondary opacity-70 w-5 h-5 flex-shrink-0 pt-0.5" />
            <span>{paper.year}</span>
          </div>
        </div>
      )}

      <div className={`w-full pr-4 lg:ml-4 ${showScore ? 'pl-0' : 'pl-4'}`}>
        <h3 className="text md:text-s font-semibold leading-4 mb-3 line-clamp-2">
          {paper.title}
        </h3>
        <p className="text-s md:text-sm text-gray-500 mb-1 line-clamp-2 flex items-center">
          <Icon icon="users" className="mr-2 text-secondary opacity-70 w-5 h-5 flex-shrink-0" style='solid' />
          <span className="line-clamp-2">{paper.authors}</span>
        </p>

        <div className="flex flex-row justify-between items-center">
          <p className="text-s md:text-sm text-gray-600 italic flex items-center">
            <Icon icon="newspaper" className="mr-2 text-secondary opacity-70 w-5 h-5 flex-shrink-0 pt-0.5" style='solid' />
            <span className="line-clamp-1 flex-grow">{paper.journal}</span>
          </p>
          {children}
        </div>
      </div>
    </motion.div>
  );
};

export default PaperListItem;
