import React from 'react';

export default function Icon({ icon, className = '', style = 'light', duotone=false }) {
    let faPrefix;
    let iconName = icon;

    if (style === 'solid') {
        faPrefix = 'fas';
    } else if (style === 'kit') {
        faPrefix = 'fa-kit'; // Custom kit prefix for your icons
    } else {
        faPrefix = 'fal'; // Light style by default
    }

    if (duotone) {
        return (
            <i className={`${className} ${faPrefix} fa-${iconName} fa-fw`}></i>
        );
    } else {
        return (
            <i className={`${className} ${faPrefix} fa-${iconName} fa-fw`}></i>
        );
    }
}
