

const UsageLimitsFree = {
    myLibraries: 1,
    maxLibrarySize: 100,
    quickSearches: 4,
    librarySearches: 4,
    smartSort: 2,
    exportRecommendations: false,
    weeklyRecommendations: false,
}


const UsageLimitsPro = {
    myLibraries: Infinity,
    maxLibrarySize: Infinity,
    quickSearches: Infinity,
    librarySearches: Infinity,
    smartSort: Infinity,
    exportRecommendations: true,
    weeklyRecommendations: true,
}

export { UsageLimitsFree, UsageLimitsPro };