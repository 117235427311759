import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import MainAppMenu from '../components/AppMenu';
import AccountView from '../views/AccountView';
import QuickSearchView from '../views/QuickSearchView';
import LibraryView from '../views/LibraryView';
import LibrarySearchView from '../views/LibrarySearchView';
import { checkSubscriptionStatus } from '../scripts/CheckSubscription';
import BookmarksView from '../views/BookmarksView';


const MainApp = () => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const { user } = useUser();
  const [customerId, setCustomerId] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState('free');
  const [userLoaded, setUserLoaded] = useState(false);
  const [activeView, setActiveView] = useState('Library');
  const [subscriptionStatus, setSubscriptionStatus] = useState('loading');
  const [trialEnd, setTrialEnd] = useState(null); // Add this line


  // check user subscription status
  useEffect(() => {
    if (user && !userLoaded) {
      const checkSubscription = async () => {
        try {
          const customer_info = await checkSubscriptionStatus(user);
          const status = customer_info.status;
          setCustomerId(customer_info.customerId);
          setSubscriptionStatus(status);
          setTrialEnd(customer_info.trialEnd); // Add this line
          setSubscriptionTier(status === 'active' || status === 'trialing' ? 'pro' : 'free');
        } catch (error) {
          console.error('Error checking subscription:', error);
          setSubscriptionStatus('error');
        }
        setUserLoaded(true);
        // console.log('User loaded', subscriptionTier);
      };

      checkSubscription();
    }
  }, [user]);

  if (!userLoaded || !user) {
    return (
      <div className="loading-screen flex items-center justify-center h-screen w-screen bg-primary">
        <div className={`
          loading-spinner animate-spin rounded-full 
          h-32 w-32 bg-primary border-secondary
          border-t-2 border-b-2
        `}
        ></div>
      </div>
    );
  }


  return (
    <>
    <div id="modal-root"></div>
    <div className="main-app bg-primary min-h-screen w-screen flex flex-col md:flex-row">
      <MainAppMenu 
        isCollapsed={isMenuCollapsed} 
        setIsCollapsed={setIsMenuCollapsed} 
        activeView={activeView}
        setActiveView={setActiveView} 
        trialEnd={trialEnd}
      />
      <main className={`
            flex-grow md:py-10 py-6 lg:px-16 
            ${isMenuCollapsed ? 'md:ml-20' : 'md:ml-64'} 
            overflow-y-auto h-screen transition-all duration-300
          `}>

        {/* Library */}
        <div className={`${activeView === 'Library' ? 'block' : 'hidden'}`}>
          <LibraryView 
            setActiveView={setActiveView} 
            isActive={activeView === 'Library'}
          />
        </div>

        {/* Quick Search */}
        <div className={`${activeView === 'Search' ? 'block' : 'hidden'}`}>
          <QuickSearchView 
            isActive={activeView === 'Search'}
          />
        </div>

        {/* Library Search */}
        <div className={`${activeView === 'LibrarySearch' ? 'block' : 'hidden'}`}>
          <LibrarySearchView 
            setActiveView={setActiveView} 
            isActive={activeView === 'LibrarySearch'}
          />
        </div>

        {/* Bookmarks */}
        <div className={`${activeView === 'Bookmarks' ? 'block' : 'hidden'}`}>
          <BookmarksView 
            isActive={activeView === 'Bookmarks'}
          />
        </div>

        {/* Account */}
        <div className={`${activeView === 'Account' ? 'block' : 'hidden'}`}>
          <AccountView 
            customerId={customerId}
          />
        </div>
      </main>
    </div>
    </>
  );
};

export default MainApp;
