import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { UsageLimitsFree } from '../scripts/UsageLimits';
import { sendGetRequest } from '../scripts/Requests';
import { useDropzone } from 'react-dropzone';

import StatusUpdates from '../elements/StatusUpdates';
import socket from '../scripts/socket';
import TextInput from '../elements/TextInput';
import PrimaryButton from '../elements/Buttons';
import Modal from '../elements/Modal';
import Icon from '../elements/Icon';
import { motion } from 'framer-motion';
import { useUserContext } from '../UserContext';

export const useLibraryLimitCheck = (userId, subscriptionTier) => {
    const [libraryCount, setLibraryCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    
    const fetchLibraryCount = async () => {
        setIsLoading(true);
        try {
            const [response, success] = await sendGetRequest(`/backend/libraries?user_id=${userId}`, 'get-user-libraries');
            if (success) {
                setLibraryCount(response.length);
            } else {
                throw new Error('Failed to fetch library count');
            }
        } catch (error) {
            console.error('Error fetching library count:', error);
            toast.error('Failed to fetch library count. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };



    const checkLibraryLimit = () => {
        if (subscriptionTier === 'pro') {
            return true;
        }

        fetchLibraryCount();
        const MAX_FREE_LIBRARIES = UsageLimitsFree.myLibraries;

        if (libraryCount >= MAX_FREE_LIBRARIES) {
            toast.error('You have reached the maximum number of libraries for a free account. Please upgrade to create more libraries.');
            return false;
        }

        return true;
    };

    return { checkLibraryLimit, isLoading, libraryCount };
};




const CreateNewLibraryFromFileModal = ({ onClose, onUpload, isOpen, setIsOpen }) => {
    const { userId, subscriptionTier } = useUserContext();
    const [isUploading, setIsUploading] = useState(false);
    const [libraryName, setLibraryName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [statusUpdates, setStatusUpdates] = useState({});

    const updateStatus = (icon, text, color="text-gray-400") => {
        setStatusUpdates({
            icon: icon,
            text: text,
            color: color
        });
      };

    // Modify the socket listeners
    useEffect(() => {
        const handleError = (data) => {
            updateStatus('exclamation-triangle', data.error, 'text-accent');
            toast.error(data.error, { toastId: 'library-creation-error' });
        };

        socket.on('library_creation_error', handleError);
        socket.on('bib_file_too_large_error', (data) => {
            updateStatus('exclamation-triangle', data.error, 'text-accent');
            toast.error("Library size is limited for free tier users. Please upgrade to LitSearch Pro to create larger libraries.")
        });

        socket.on('bib_parsing_started', (data) => {
            updateStatus('robot', [
                'Parsing .bib file...',
                'This might take a while for large files',
                "Reading your library's content..."
            ]);
        });

        socket.on('bib_parsing_completed', (data) => {
            updateStatus('check', `Parsed ${data.n_papers} papers`);
        });

        socket.on('library_creation_started', (data) => {
            updateStatus('robot', 'Creating library...');
        });

        socket.on('library_creation_progress', (data) => {
            updateStatus('robot', `Creating library... ${data.progress.toFixed(2) * 100}%`);
        });

        socket.on('library_creation_success', (data) => {
            updateStatus('check', 'Library created successfully', 'text-secondary');
        });

        return () => {
            socket.off('library_creation_error', handleError);
            socket.off('bib_file_too_large_error', handleError);
            socket.off('bib_parsing_started');
            socket.off('bib_parsing_completed');
            socket.off('library_creation_started');
            socket.off('library_creation_progress');
            socket.off('library_creation_success');
        };
    }, [isUploading]);

    const { checkLibraryLimit } = useLibraryLimitCheck(userId, subscriptionTier);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.bib',
        onDrop: files => {
            setSelectedFile(files[0]);
        }
    });

    const handleUpload = async () => {
        if (!libraryName.trim()) {
            toast.warning('Please specify a library name.');
            return;
        }
        if (!selectedFile) {
            toast.warning('Please select a .bib file.');
            return;
        }

        if (!checkLibraryLimit()) {
            return;
        }

        setIsUploading(true);
        await onUpload(selectedFile, libraryName);
        setIsUploading(false);
    };

    const isUploadEnabled = libraryName.trim() && selectedFile;

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <motion.div 
                className="px-4 py-4"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                <h2 className="text-2xl font-bold mb-4 text-gray-600">
                    <Icon icon="light-building-columns-circle-plus" style="kit" className="mr-2 text-accent" />
                    Add New Library
                </h2>
                <p className="mb-2 text-gray-600">Please enter a library name and upload a .bib file to create a new library.</p>
                
                <TextInput 
                    placeholderTexts={["Enter library name"]}
                    label="Library Name" 
                    value={libraryName}
                    onTextChange={setLibraryName}
                    useTypewriter={false}
                    showButton={false}
                    maxLength={128}
                    icon="pencil"
                    iconStyle="light"
                    className="mb-4 mt-8"
                />

                {isUploading ? (
                    <>
                    <StatusUpdates {...statusUpdates} isVisible={true} />

                    <p className="text-gray-600 mt-4 w-4/5 mx-auto text-center">
                        Creating the library may take a few minutes. You can close this dialog and continue
                        using LitSearch. <br/>
                        <Icon icon="binoculars" style="solid" className="mr-2 text-xl" /> Come back in a while! <Icon icon="binoculars" style="light" className="ml-2 text-xl" />
                    </p>
                    </>
                ) : (
                    <>
                    <motion.div 
                        {...getRootProps()} 
                        className={`
                            mt-4 border-2 border-secondary/20 
                            py-4 px-8 text-center cursor-pointer
                            bg-primary/50 backdrop-blur-sm
                            text-gray-600 w-full mx-auto rounded-lg
                            hover:bg-secondary/10 transition-colors duration-200
                            ${isDragActive ? 'border-secondary/50 bg-secondary/10' : 'border-secondary/20'}
                        `}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <input {...getInputProps()} />

                        {isDragActive ? (
                            <p>Drop the file here ...</p>
                        ) : (
                            <div className="flex flex-row items-center w-full py-8">
                                <i className="fas fa-file-upload text-3xl mr-4 text-secondary/80"></i>
                                <p>{selectedFile ? <span>Selected: <span className="highlight-border">{selectedFile.name}</span></span> : <span>Drag and drop a <span className="highlight-border">.bib file </span> here, or click to select a file</span>}</p>
                            </div>
                        )}

                    </motion.div>
                   
                    <div className="flex flex-col items-center">
                        <p className="mb-2 text-gray-600 text-sm mx-auto mt-4">
                                <span className="text-secondary font-semibold"><Icon icon="lightbulb" style="light"/> hint: </span>
                                to get the most out of Litsearch we recommend libraries with <span className="highlight-border">25-250 papers</span> and focused on a specific topic.
                        </p>
                        {/* <p className="mb-2 text-gray-600 text-sm w-2/3 mx-auto mt-4">
                                <span className="text-secondary font-semibold"><Icon icon="lightbulb" style="light"/> hint: </span>
                                you can always change the name later!
                        </p> */}
                    </div>


                    </>
                )}

                <div className="mt-6 flex justify-end">
                    <PrimaryButton
                        onClick={handleUpload}
                        disabled={!isUploadEnabled && !isUploading}
                    >
                        Create Library
                    </PrimaryButton>
                </div>
            </motion.div>
        </Modal>
    );
};



const CreateNewLibraryFromPapersModal = ({ isOpen, onClose, onCreateLibrary, selectedPapersCount, sourceLibraryName }) => {
    const { userId, subscriptionTier } = useUserContext();
    const [newLibraryName, setNewLibraryName] = useState('');
    const [statusUpdates, setStatusUpdates] = useState({});

    const updateStatus = (icon, text, color="text-gray-400") => {
        setStatusUpdates({
            icon: icon,
            text: text,
            color: color
        });
      };

    useEffect(() => {
        if (isOpen) {
            setStatusUpdates({});
            setNewLibraryName('');
        }
    }, [isOpen]);

    useEffect(() => {
        const handleError = (data) => {
            updateStatus('exclamation-triangle', data.error, 'text-accent');
            toast.error(data.error, { toastId: 'library-creation-error' });
        };

        socket.on('library_creation_error', handleError);
        socket.on('bib_file_too_large_error', handleError);

        socket.on('library_creation_progress', (data) => {
            updateStatus('robot', `Creating library... ${data.progress.toFixed(2) * 100}%`);
        });
        socket.on('library_creation_success', (data) => {
            updateStatus('check', 'Library created successfully', 'text-secondary');
        });

        return () => {
            socket.off('library_creation_progress');
            socket.off('library_creation_error');
            socket.off('library_creation_success');
            socket.off('bib_file_too_large_error');
        };
    }, []);

    const { checkLibraryLimit } = useLibraryLimitCheck(userId, subscriptionTier);

    const handleClose = () => {
        setStatusUpdates({});
        setNewLibraryName('');
        onClose();
    };

    const handleCreateLibrary = async () => {
        if (!checkLibraryLimit()) {
            return;
        }

        if (subscriptionTier === 'free' && selectedPapersCount > UsageLimitsFree.maxLibrarySize) {
            toast.error(`Free tier users can only create libraries with up to ${UsageLimitsFree.maxLibrarySize} papers. Please upgrade to create a larger library.`, { toastId: 'library-size-error' });
            return;
        }

        onCreateLibrary(newLibraryName);
        setStatusUpdates({
            icon: 'spinner',
            text: 'Starting library creation...',
            color: 'text-secondary'
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <motion.div 
                className="px-4 py-4"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                <h2 className="text-2xl font-bold mb-4 text-gray-600">
                    <Icon icon="light-building-columns-circle-plus" style="kit" className="mr-2 text-accent" />
                    Create library from selection
                </h2>
                <p className="mb-4 text-gray-600">Create a new library with {selectedPapersCount} papers selected from {sourceLibraryName}</p>
                <TextInput
                    placeholderTexts={["Enter new library name"]}
                    label="New Library Name"
                    value={newLibraryName}
                    onTextChange={setNewLibraryName}
                    useTypewriter={false}
                    showButton={true}
                    maxLength={128}
                    clickOnEnter={true}
                    onButtonClick={handleCreateLibrary}
                    buttonText="Create Library"
                    buttonProps={{
                        disabled: newLibraryName.length === 0,
                        icon: 'plus'
                    }}
                    className="mb-4"
                />
                <StatusUpdates {...statusUpdates} isVisible={Object.keys(statusUpdates).length > 0} />
            </motion.div>
        </Modal>
    );
};

export { CreateNewLibraryFromFileModal, CreateNewLibraryFromPapersModal, };