import { sendGetRequest } from './Requests';
import { UsageLimitsFree } from './UsageLimits';
import { toast } from 'react-toastify';

export default async function fetchLibraries(userId, subscriptionTier, setLibraries, view) {
    if (userId === undefined || !userId) {
        return;
    }
    
    try {
        const [response, success] = await sendGetRequest('/backend/libraries', 'get-libraries', { user_id: userId });
        if (!success) {
            throw new Error('Error fetching libraries');
        }

        // console.log('Found', response.length, 'libraries for user', userId, 'in view', view)
        let libraries;
        if (subscriptionTier === 'free'){
            libraries = response.slice(0, UsageLimitsFree['myLibraries']);
        } else {
            libraries = response;
        }
        setLibraries(libraries);
    } catch (error) {
        toast.error('An error occurred while fetching libraries.');
    }
};