import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import posthog from 'posthog-js';

import { faChevronDown,  faChevronUp } from '@fortawesome/free-solid-svg-icons';

import fetchLibraries from '../scripts/FetchUserLibraries';
import ViewContainer from '../elements/ViewContainer';
import Modal, { ConfirmationModal } from '../elements/Modal';
import sendPostRequest, { sendGetRequest } from '../scripts/Requests';
import TextInput from '../elements/TextInput';
import { TooltipButton } from '../elements/Buttons';
import Icon from '../elements/Icon';
import { PlaceholdersList } from '../components/PapersDisplay'
import SelectLibrary from '../elements/SelectLibrary';
import { CreateNewLibraryFromFileModal, CreateNewLibraryFromPapersModal, useLibraryLimitCheck } from '../components/LibraryCreationModals';
import PaperListItem from '../components/PaperListItem'
import PaginatedList from '../elements/Pagination'; // You'll need to create this component
import { useUserContext } from '../UserContext';

/* -------------------------------------------------------------------------- */
/*                              LIBRARY CONTROLS                              */
/* -------------------------------------------------------------------------- */

const LibraryControls = ({ library, paperCount, nPapersSelected, onAddPapers, onExport, onUseLibrary, onDeleteLibrary, onRemovePapers, onSelectAll, onDeselectAll, onMakeNewLibrary, onRenameLibrary }) => {
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);


    const iconButtonStyle = `
                papers-display-controls-button
                border-b-2
                z-50
        `

    const toggleIcon = descriptionExpanded ? faChevronUp : faChevronDown;



    return (
        <div className="flex flex-col gap-3 -mb-2">
            <div className="flex flex-col gap-1">
                <p className="text-xl font-semibold">
                  <Icon icon="building-columns" style="light" className="mr-2" />
                  {library.name}
                    {/* <FontAwesomeIcon icon={toggleIcon} className="ml-4 text-gray-500 hover:text-secondary cursor-pointer text-md" onClick={() => setDescriptionExpanded(!descriptionExpanded)} /> */}
                </p>
                {/* <motion.div
                    initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
                    animate={{ 
                        height: descriptionExpanded ? "auto" : 0,
                        opacity: descriptionExpanded ? 1 : 0,
                        overflow: 'auto'
                    }}
                    transition={{ duration: 0.3 }}
                >
                    <p className="text-gray-600 bg-white rounded-md border border-gray-300 text-sm p-2 max-h-96 overflow-y-auto">{library.description}</p>
                </motion.div> */}
            </div>

            <div className="flex justify-between items-center mb-4 z-50  lg:px-4">                
                
                <div className="text-gray-500 flex flex-row gap-4 items-center">
                    {nPapersSelected < paperCount ? (
                        <TooltipButton icon='square' onClick={onSelectAll} tooltip="Select all papers in this library" className={iconButtonStyle} />
                    ) : (
                        <TooltipButton icon='square-check' onClick={onDeselectAll} tooltip="Deselect all papers in this library" className={iconButtonStyle} duotone={true} style='solid'/>
                    )}

                    { nPapersSelected === 0 ? 
                        <p className="text-sm"><span className="font-semibold">{paperCount}</span> papers in this library</p> : 
                        <p className="text-sm"><span className="font-semibold">{nPapersSelected}</span>/{paperCount} papers selected</p> 
                    }
                </div>

                {nPapersSelected > 0 && (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{duration: 0.2}}
                            className="flex flex-row items-center gap-4 mr-4"
                        >
                            <TooltipButton icon='light-memo-slash' iconStyle='kit' onClick={onRemovePapers} tooltip="Remove selected papers from this library." className={iconButtonStyle + 'red'} />
                            <TooltipButton icon='light-building-columns-circle-plus' iconStyle='kit' onClick={onMakeNewLibrary} tooltip="Create a new library with the selected papers." className={iconButtonStyle } />
                        </motion.div>
                )}


                <div className="flex flex-row items-center gap-4">                    
                    <TooltipButton icon='pencil' onClick={onRenameLibrary} tooltip="Rename library" className={iconButtonStyle} />
                    <TooltipButton icon='plus' onClick={onAddPapers} tooltip="Add papers" className={iconButtonStyle} />
                    <TooltipButton icon='file-arrow-down' onClick={onExport} tooltip="Export library" className={iconButtonStyle} />
                    <TooltipButton icon='trash' onClick={onDeleteLibrary} tooltip="Delete this library" className={ iconButtonStyle + 'red'} />
                </div>
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */
/*                               LIBRARY DETAILS                              */
/* -------------------------------------------------------------------------- */


const LibraryDetails = ({ selectedLibrary, setSelectedLibrary, actionCallback, setActiveView, isActive }) => {
  const { userId, subscriptionTier } = useUserContext();
  const [papers, setPapers] = useState([]);
  const [selectedPapers, setSelectedPapers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({ isOpen: false, message: '', onConfirm: null });
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isNewLibraryModalOpen, setIsNewLibraryModalOpen] = useState(false);
  const [newLibraryName, setNewLibraryName] = useState('');
  const { checkLibraryLimit, isLoading: isLibraryCountLoading, libraryCount } = useLibraryLimitCheck(userId, subscriptionTier);
  const [isAddPapersModalOpen, setIsAddPapersModalOpen] = useState(false);
  const [paperInput, setPaperInput] = useState('');

  useEffect(() => {
    if (selectedLibrary) {
      fetchLibraryPapers();
      setSelectedPapers([])
    }

  }, [selectedLibrary, isActive]);

  const fetchLibraryPapers = async () => {
    setIsLoading(true);
    try {
      const [response, success] = await sendGetRequest(`/backend/libraries/${selectedLibrary.id}/papers`, 'get-library-papers');
      if (!success) {
        throw new Error('Error fetching library papers');
      }
      setPapers(response);
    } catch (error) {
      console.error('Error fetching library papers:', error);
      toast.error('An error occurred while fetching library papers.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaperClick = (paper) => {
    if (selectedPapers.includes(paper.paperId)) {
      setSelectedPapers(selectedPapers.filter(id => id !== paper.paperId));
    } else {
      setSelectedPapers([...selectedPapers, paper.paperId]);
    }
  };

  const handleApiRequest = async (apiFunction, successMessage, errorMessage, eventName) => {
    try {
      const [response, success] = await apiFunction();
      if (success) {
        toast.success(successMessage);
        fetchLibraryPapers();
        actionCallback();
        posthog.capture(eventName, { library_id: selectedLibrary.id, library_name: selectedLibrary.name });
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMessage);
      posthog.capture(`${eventName}_failed`, { library_id: selectedLibrary.id, library_name: selectedLibrary.name, error: error.message });
    } finally {
        fetchLibraryPapers();
        actionCallback();
    }
  };

  const handleAddPapers = () => {
    setIsAddPapersModalOpen(true);
  };

  const handleAddPaperSubmit = async () => {
    try {
      const [response, success, error] = await sendPostRequest(
        `/backend/libraries/${selectedLibrary.id}/papers`,
        { paper_title: paperInput },
        'add-paper-to-library'
      );

      if (success) {
        toast.success("Paper added successfully");
        fetchLibraryPapers();
        setIsAddPapersModalOpen(false);
        setPaperInput('');
      } else {
        toast.error(error);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Failed to add paper");
    }
  };

  const handleExport = () => {
    // This function would typically trigger a file download
    toast.info("Export functionality not implemented yet.");
  };


  const handleDeleteLibrary = () => {
    setConfirmationModal({
      isOpen: true,
      message: `Are you sure you want to delete library "${selectedLibrary.name}"? All data will be lost.`,
      onConfirm: () => {
        handleApiRequest(
          () => sendPostRequest(`/backend/libraries/${selectedLibrary.id}`, {}, 'delete-library', 'DELETE'),
          "Library deleted successfully",
          "Failed to delete library",
          'library_deleted'
        );
        setConfirmationModal({ isOpen: false, message: '', onConfirm: null });
        setSelectedLibrary(null);
      }
    });
  };

  const handleRemovePapers = () => {
    if (selectedPapers.length === 0) {
      toast.warn("No papers selected for removal");
      return;
    }

    setConfirmationModal({
      isOpen: true,
      message: `Are you sure you want to delete ${selectedPapers.length} paper${selectedPapers.length > 1 ? 's' : ''} from "${selectedLibrary.name}"?`,
      onConfirm: async () => {
        try {
          const [response, success] = await sendPostRequest(
            `/backend/libraries/${selectedLibrary.id}/papers`,
            { paper_ids: selectedPapers },
            'remove-papers',
            'DELETE'
          );

          if (success) {
            toast.success(response.message || "Papers removed successfully");
            fetchLibraryPapers();
            setSelectedPapers([]);
            posthog.capture('papers_removed_from_library', { 
              library_id: selectedLibrary.id, 
              library_name: selectedLibrary.name, 
              papers_count: selectedPapers.length 
            });
          } else {
            throw new Error(response.error || "Failed to remove papers");
          }
        } catch (error) {
          console.error(error);
          toast.error(error.message || "Failed to remove papers");
        }
        setConfirmationModal({ isOpen: false, message: '', onConfirm: null });
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedPapers(papers.map(paper => paper.paperId));
  };

  const handleDeselectAll = () => {
    setSelectedPapers([]);
  };

  const handleRenameLibrary = () => {
    setIsRenameModalOpen(true);
  };

  const handleRenameConfirm = async () => {
    try {
      const [response, success] = await sendPostRequest(
        `/backend/libraries/${selectedLibrary.id}`,
        { name: newLibraryName },
        'rename-library',
        'PUT'
      );

      if (success) {
        toast.success("Library renamed successfully");
        setSelectedLibrary({ ...selectedLibrary, name: newLibraryName });
        actionCallback();
      } else {
        throw new Error(response.error || "Failed to rename library");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Failed to rename library");
    }
    setIsRenameModalOpen(false);
    setNewLibraryName('');
  };

  const handleMakeNewLibrary = () => {
    if (checkLibraryLimit()) {
      setIsNewLibraryModalOpen(true);
    } else {
      setActiveView('Account');
    }
  };

  const handleCreateNewLibrary = async (newLibraryName) => {
    try {
        const [response, success] = await sendPostRequest(
            '/backend/libraries',
            {
                user_id: userId,
                library_name: newLibraryName,
                paper_ids: selectedPapers,
                subscription_tier: subscriptionTier,
                is_free_tier: subscriptionTier === 'Free'
            },
            'create-library-from-selection'
        );
        if (success) {
            toast.success("Library created successfully");
            fetchLibraryPapers();
            actionCallback();
        } else {
            throw new Error(response.error || "Failed to create new library");
        }
        setIsNewLibraryModalOpen(false)
    } catch (error) {
        console.error(error);
        toast.error(error.message || "Failed to create new library");
        setIsNewLibraryModalOpen(false);
    }
};

  const renderListItem = (paper, index) => (
    <motion.div
      key={`${paper.paperId}-${index}`}
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
      }}
      transition={{ duration: 0.3, delay: index < 10 ? index * 0.05 : 0 }}
    >
      <PaperListItem
        paper={paper}
        isSelected={selectedPapers.includes(paper.paperId)}
        onClick={() => handlePaperClick(paper)}
        isLoading={isLoading}
      />
    </motion.div>
  );

  return (
    <div className="flex flex-col w-10/12 lg:w-4/5 max-w-3xl mx-auto min-h-[75vh] z-0">
        <LibraryControls
            library={selectedLibrary}
            paperCount={papers.length}
            nPapersSelected={selectedPapers.length}
            onAddPapers={handleAddPapers}
            onExport={handleExport}
            onDeleteLibrary={handleDeleteLibrary}
            onRemovePapers={handleRemovePapers}
            onSelectAll={handleSelectAll}
            onDeselectAll={handleDeselectAll}
            onMakeNewLibrary={handleMakeNewLibrary}
            onRenameLibrary={handleRenameLibrary}
        />
    
        <div className="flex flex-col gap-1 max-h-[70vh]">
          {papers.length > 0 ? (
            <PaginatedList
              items={papers}
              renderItem={renderListItem}
              itemsPerPage={100}
            /> 
          ) : (
            <PlaceholdersList isLoading={true} />
          )}
        </div>


        <ConfirmationModal
          isOpen={confirmationModal.isOpen}
          onClose={() => setConfirmationModal({ isOpen: false, message: '', onConfirm: null })}
          onConfirm={confirmationModal.onConfirm}
          message={confirmationModal.message}
        />

        {/* Rename Library Modal */}
        <Modal isOpen={isRenameModalOpen} onClose={() => setIsRenameModalOpen(false)}>
          <div className="">
            <h2 className="text-2xl font-bold mb-4 text-secondary">Rename Library</h2>
            <TextInput
              placeholderTexts={["Enter new library name"]}
              label="New Library Name"
              value={newLibraryName}
              onTextChange={setNewLibraryName}
              useTypewriter={false}
              showButton={true}
              maxLength={128}
              onButtonClick={handleRenameConfirm}
              buttonText="Rename Library"
              buttonProps={{
                disabled: newLibraryName.length === 0,
                icon: 'pencil'
              }}
              clickOnEnter={true}
            />
            
          </div>
        </Modal>

        {/* Create New Library Modal */}
        <CreateNewLibraryFromPapersModal
            isOpen={isNewLibraryModalOpen}
            onClose={() => setIsNewLibraryModalOpen(false)}
            onCreateLibrary={handleCreateNewLibrary}
            selectedPapersCount={selectedPapers.length}
            sourceLibraryName={selectedLibrary.name}
        />

        <Modal isOpen={isAddPapersModalOpen} onClose={() => setIsAddPapersModalOpen(false)}>
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4 text-secondary">Add Paper to Library</h2>
            <p className="text-sm text-slate-500 mb-4">
              You can add a paper to your library by entering its title.
              We're working on other and better ways to add papers to your library - they'll come soon <Icon icon="rocket" className="text-accent ml-2" />
            </p>
            <TextInput
              placeholderTexts={["Enter the title of the paper you'd like to add"]}
              value={paperInput}
              onTextChange={setPaperInput}
              useTypewriter={false}
              showButton={true}
              onButtonClick={handleAddPaperSubmit}
              buttonText="Add Paper"
              buttonProps={{
                disabled: paperInput.length === 0,
                icon: 'plus'
              }}
              clickOnEnter={true}
            />
          </div>
        </Modal>
    </div>
  );
};

const LibraryDetailsPlaceholder = () => {
    return (
        <div className="text-2xl text-slate-300 flex flex-col gap-8 w-10/12 lg:w-3/5 mx-auto">
            <span className="lg:pl-10">Start by selecting a library, or add a new one.</span>
            <PlaceholdersList />
        </div>
    );
};




/* -------------------------------------------------------------------------- */
/*                                  MAIN VIEW                                 */
/* -------------------------------------------------------------------------- */

const LibraryView = ({ setActiveView, isActive }) => {
    const { userId, subscriptionTier } = useUserContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [libraries, setLibraries] = useState([]);
    const [selectedLibrary, setSelectedLibrary] = useState(null);
    const { checkLibraryLimit, isLoading: isLibraryCountLoading, libraryCount } = useLibraryLimitCheck(userId, subscriptionTier);

    useEffect(() => {
        fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView');
    }, [userId, isActive]);

    const handleAddLibraryClick = () => {
        if (checkLibraryLimit()) {
            startLibraryCreation();
            posthog.capture('add_library_clicked', { subscription_tier: subscriptionTier });
        } else {
            setActiveView('Account');
            posthog.capture('library_limit_reached', { subscription_tier: subscriptionTier });
        }
    };

    const startLibraryCreation = () => {
        setIsModalOpen(true);
    };

    const handleLibraryCreation = async (file, name) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);
        formData.append('library_name', name);
        formData.append('subscription_tier', subscriptionTier);
        formData.append('is_free_tier', subscriptionTier === 'Free');

        const [response, success] = await sendPostRequest(
            '/backend/libraries',
            formData,
            'create-library'
        );

        if (!success) {
            toast.error(response.error || "There was an error creating the library. Please try again or contact support.");
            posthog.capture('library_creation_failed', { error: response.error });
        } else {
            toast.success('Library created successfully');
            posthog.capture('library_created', { name: name, subscription_tier: subscriptionTier });
        }

        setIsModalOpen(false);
        fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView');
    };

    const handleLibraryCreated = () => {
        fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView');
    };

    const handleSelectLibrary = (library) => {
        setSelectedLibrary(library);
    };

    

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        }
    };

    return (
        <ViewContainer title="Manage your libraries" icon="building-columns">
            <motion.div 
                className="relative lg:flex lg:flex-row"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <motion.div 
                    className="lg:w-1/4 mb-4"
                    variants={itemVariants}
                >
                    <SelectLibrary 
                        libraries={libraries} 
                        onButtonClick={handleAddLibraryClick} 
                        selectedLibrary={selectedLibrary}
                        onSelectLibrary={handleSelectLibrary}
                        subscriptionTier={subscriptionTier}
                        setActiveView={setActiveView}
                        libraryCount={libraryCount}
                        isLibraryCountLoading={isLibraryCountLoading}
                    />
                </motion.div>
                <div 
                    className="lg:w-3/4"
                >
                    {selectedLibrary ? (
                        <LibraryDetails 
                            key={selectedLibrary.id}
                            userId={userId}
                            subscriptionTier={subscriptionTier}
                            selectedLibrary={selectedLibrary} 
                            actionCallback={() => fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView')}
                            setSelectedLibrary={setSelectedLibrary}
                            setActiveView={setActiveView}
                            isActive={isActive}
                        />
                    ) : (
                        <LibraryDetailsPlaceholder />
                    )}
                </div>
            </motion.div>

            {isModalOpen &&
                <CreateNewLibraryFromFileModal 
                    onClose={handleLibraryCreated} 
                    onUpload={handleLibraryCreation} 
                    isOpen={isModalOpen} 
                    setIsOpen={setIsModalOpen}
                />
            }
        </ViewContainer>
    );
};

export default LibraryView;