import React, { useState } from 'react';
import TypeWriter from 'typewriter-effect';
import { PrimaryButton } from './Buttons';
import { motion, AnimatePresence } from 'framer-motion';
import TextareaAutosize from 'react-textarea-autosize';

import Icon from './Icon';

const isMobile = window.innerWidth < 768;

const TextInput = ({ 
  placeholderTexts, 
  useTypewriter = true, 
  className = '',
  inputClassName = '',
  borderRadius = 'rounded-md',
  borderColor = 'gray-300',
  activeBorderColor = 'gray-600',
  placeholderColor = 'gray-400',
  textColor = 'gray-900',
  fontSize = 'text-base',
  padding = 'px-4 py-2',
  icon,
  buttonText = 'Press me',
  onTextChange,
  onEnterPress,
  onButtonClick,
  buttonProps = {},
  onSettings,
  minRows = isMobile ? 2 : 1,
  maxRows = 5,
  showButton = true,
  children, 
  maxLength,
  clickOnEnter = false,
  value, 
  buttonComponent: ButtonComponent = PrimaryButton,
}) => {
    const [inputValue, setInputValue] = useState(value || ''); // Update this line
    const [isFocused, setIsFocused] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [charCount, setCharCount] = useState(value ? value.length : 0); // Update this line

    const handleInputChange = (e) => {
      if (clickOnEnter && e.key === 'Enter') {
        onButtonClick(inputValue);
        e.preventDefault();
        return;
      }
      const newValue = e.target.value.slice(0, maxLength);
      setInputValue(newValue);
      setCharCount(newValue.length);
      if (onTextChange) onTextChange(newValue);
    };

    
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && onEnterPress) {
        e.preventDefault(); // Prevent default behavior
        onEnterPress(inputValue);
      }
    };

    const _borderColor = `border-${borderColor}`
    const _activeBorderColor = `border-${activeBorderColor}`
    const _focusWithin = `focus-within:${_activeBorderColor}`
    const _text = `text-${textColor}`

    return (
        <div className={`flex flex-col ${className}  w-11/12 md:w-4/5 mx-auto`}>
            <div className="flex flex-col sm:flex-row items-stretch w-full">
                <div className={`
                  relative flex-grow w-full sm:w-auto ${borderRadius} ${fontSize}
                  border
                  ${_borderColor}
                  ${_focusWithin}
                  focus-within:outline-none
                  ${_text}
                  ${inputClassName}
                  bg-white
                  mb-2
                `}>
                  {icon && (
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">
                      <Icon icon={icon} />
                    </div>
                  )}
                  <TextareaAutosize
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className={`
                      w-full bg-transparent focus:outline-none resize-none 
                      ${icon ? 'pl-10' : 'pl-4'} pr-4 
                      ${isMobile ? 'py-2' : 'py-2'}
                    `}
                    placeholder={useTypewriter ? '' : placeholderTexts[0]}
                    minRows={minRows}
                   maxRows={maxRows}
                  />
                  {useTypewriter && !inputValue && !isFocused && (
                      <div className={`absolute inset-y-0 left-0 flex items-center pointer-events-none text-${placeholderColor} ${fontSize} ${ icon ? 'pl-10' : 'pl-4'}`}>
                          <TypeWriter
                              options={{
                                  strings: placeholderTexts,
                                  autoStart: true,
                                  loop: true,
                                  delay: 10,
                                  deleteSpeed: 25,
                                  cursor: '|',
                              }}
                          />
                      </div>
                  )}
                </div>
                {showButton && (
                <ButtonComponent 
                  className={`mb-2 sm:ml-2 flex-shrink-0 w-full sm:w-auto`}
                  onClick={() => onButtonClick && onButtonClick(inputValue)}

                  {...buttonProps} 
                >
                  {buttonText}
                </ButtonComponent>
                )}
            </div>
            {maxLength && (
              <div className="text-right text-sm text-gray-500 mt-1">
                {charCount}/{maxLength}
              </div>
            )}
            {(children || onSettings) && (
                <div className="relative mt-1 flex items-center">
                    {onSettings && (
                        <button
                            className="text-gray-400 hover:text-gray-600 focus:outline-none ml-2"
                            onClick={onSettings}
                        >
                            <Icon icon="cog" />
                        </button>
                    )}
                    {children && (
                        <button
                            className="text-gray-400 hover:text-gray-600 focus:outline-none ml-4"
                            onClick={() => setShowTooltip(!showTooltip)}
                        >
                            help
                        </button>
                    )}
                </div>
            )}
            <AnimatePresence>
                {showTooltip && (
                    <motion.div
                        initial={{ opacity: 0, y: -10, height: 0, overflow: 'hidden' }}
                        animate={{ opacity: 1, y: 0, height: 'auto', overflow: 'visible' }}
                        exit={{ opacity: 0, y: -10, height: 0, overflow: 'hidden' }}
                        transition={{ duration: 0.2 }}
                        className={`
                            mt-1 py-4 ${borderRadius}
                            border ${_borderColor} bg-white ${_text} ${fontSize}
                            z-10 w-4/5 mx-auto
                        `}
                        style={{ width: '80%', margin: '0 auto' }}
                    >
                        <div className="flex flex-col gap-2 w-full">
                            {children}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
                
        </div>
    );
};

export default TextInput;