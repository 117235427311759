import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import posthog from 'posthog-js';

import Icon from '../elements/Icon';
import TopicsMenu from '../elements/TopicsMenu';
import PaperDetails, { PaperDetailsPlaceholder } from './PaperDetails';
import Modal from '../elements/Modal';
import sendPostRequest from '../scripts/Requests';
import { SmartSortModal } from './SmartSort';
import PaperListItem from './PaperListItem';
import { useUserContext } from '../UserContext';

export function generatePastelColor() {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 90%)`;
  }


const PapersDisplay = ({ 
      papers = [], 
      setPapers, 
      isLoading, 
      showSmartSort=true, 
      showTopicsMenu=true, 
      listItemParams={}, 
      paperDetailsParams={},
  }) => {
  const { user_id, isFreeTier } = useUserContext();
  const [selectedPaper, setSelectedPaper] = useState(null);
  const [sortBy, setSortBy] = useState('relevance');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [isSmartSortModalOpen, setIsSmartSortModalOpen] = useState(false);
  const [smartSortPrompt, setSmartSortPrompt] = useState('');

  const paperDetailsRef = useRef(null);

  const heightParams = "h-[100vh] max-h-[1000px]"
  const mobileHeightParams = "h-[100vh] max-h-[800px]"

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (papers.length > 0) {
      setSelectedPaper(papers[0]);
    }
    if (papers.length === 0) {
      setSelectedPaper(null);
    }
  }, [papers]);

  useEffect(() => {
    if (paperDetailsRef.current) {
      paperDetailsRef.current.scrollTop = 0;
    }
  }, [selectedPaper]);

  const sortedPapers = React.useMemo(() => {
    let sorted = [...papers];
    if (sortBy === 'year') {
      sorted.sort((a, b) => a.year - b.year);
    } else if (sortBy === 'citations') {
      sorted.sort((a, b) => a.n_citations - b.n_citations);
    } else if (sortBy === 'relevance') {
      const papersWithScore = sorted.filter(paper => paper.score != null);
      if (papersWithScore.length > sorted.length / 2) {
        sorted.sort((a, b) => (a.score || 0) - (b.score || 0));
      }
      // If not enough papers have scores, keep the default order
    }

    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }, [papers, sortBy, sortOrder]);

  const filteredPapers = React.useMemo(() => {
    return sortedPapers.filter(paper => {
      const topicMatch = !selectedTopic || (paper.fieldsOfStudy && paper.fieldsOfStudy.includes(selectedTopic));
      // const keywordMatch = !selectedKeyword || (paper.keywords && paper.keywords.includes(selectedKeyword));
      return topicMatch;
    });
  }, [sortedPapers, selectedTopic]);

  const topicCounts = React.useMemo(() => {
    const counts = {};
    papers.forEach(paper => {
      paper.fieldsOfStudy.forEach(topic => {
        counts[topic] = (counts[topic] || 0) + 1;
      });
    });
    return Object.entries(counts).sort((a, b) => b[1] - a[1]);
  }, [papers]);

  const keywordCounts = React.useMemo(() => {
    const counts = {};
    papers.forEach(paper => {
      (paper.keywords || []).forEach(keyword => {
        counts[keyword] = (counts[keyword] || 0) + 1;
      });
    });
    return Object.entries(counts).sort((a, b) => b[1] - a[1]);
  }, [papers]);

  const topicColors = React.useMemo(() => {
    const colors = {};
    topicCounts.forEach(([topic]) => {
      colors[topic] = generatePastelColor();
    });
    return colors;
  }, [topicCounts]);


  const handleSmartSort = () => {
    if (isLoading) return;
    setIsSmartSortModalOpen(true);
    posthog.capture('Smart Sort Opened', { paperCount: papers.length });
  }

  const handleSmartSortSubmit = async () => {
    try {
      posthog.capture('Smart Sort Submitted', { prompt: smartSortPrompt });
      const [response, success] = await sendPostRequest('/backend/smart-sort', {
        papers: papers,
        prompt: smartSortPrompt,
        user_id: user_id,
        is_free_tier: isFreeTier
      });

      if (success) {
        const updatedPapers = response.papers;
        // Update the papers state with the new scores
        setPapers(updatedPapers);
        // Re-apply the sort by relevance
        setSortBy('relevance');
        setIsSmartSortModalOpen(false);
        posthog.capture('Smart Sort Completed', { paperCount: updatedPapers.length });
      } else {
        console.error('Failed to perform SmartSort');
        toast.error('Failed to perform SmartSort, please try again.');
        posthog.capture('Smart Sort Failed', { error: 'API Error' });
      }
    } catch (error) {
      console.error('Error during SmartSort:', error);
      toast.error('Failed to perform SmartSort, please try again.');
      posthog.capture('Smart Sort Failed', { error: error.message });
    }
  }


  const handleOpenDetails = (paper) => {
    setSelectedPaper(paper);
    if (isMobile) {
      setIsMobileModalOpen(true);
    }
    posthog.capture('Paper Details Opened', { paperId: paper.paperId, title: paper.title });
  };


  const listWidthDesktop = papers.length === 0 ? "70%" : (
    showTopicsMenu ? "50%" : "60%"
  )


  return (
    <motion.div
      animate={(isLoading && papers.length > 0) ? { opacity: [0.3, 1, 0.3] } : { opacity: 1 }}
      transition={(isLoading && papers.length > 0) ? { duration: 1.5, repeat: Infinity, ease: "easeInOut" } : {}}
    >
      <div className="w-full max-w-7xl mx-auto mt-5">
        <div className="flex flex-col lg:flex-row gap-8"

        >
          {/* Topics Menu */}
          {showTopicsMenu && (
          <motion.div 
            className={`order-2 lg:w-[15%] ${isMobile ? "mx-auto -mb-4" : ""}`}
            initial={{ width: papers.length === 0 ? "0%" : isMobile ? "90%" : "15%" }}
            animate={{ width: papers.length === 0 ? "0%" : isMobile ? "90%" : "15%" }}
            transition={{ duration: 0.3 }}
            
          >
            <TopicsMenu
              topicCounts={topicCounts}
              keywordCounts={keywordCounts}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              selectedKeyword={selectedKeyword}
              setSelectedKeyword={setSelectedKeyword}
              topicColors={topicColors}
              height={isMobile ? mobileHeightParams : heightParams}
              onSmartSortButtonClick={showSmartSort ? handleSmartSort : null}
            />
          </motion.div>
          )}

          

          { /* ------------------------------- Papers List ------------------------------  */}
          <motion.div
            className={`
                        order-2
                        
                        ${isMobile ? mobileHeightParams : heightParams} 
                        flex flex-col
                        ${isMobile ? "mx-auto" : ""}
                    `}
            initial={{ width: isMobile ? "90%" : listWidthDesktop}}
            animate={{ width: isMobile ? "90%" : listWidthDesktop}}
            transition={{ duration: 0.3 }}
          >
            {filteredPapers.length === 0  ? (
              <PlaceholdersList isLoading={isLoading} />
            ) : (
                <>
                    <SortingMenu
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        totalPapers={filteredPapers.length}
                        isLoading={isLoading}
                    />
              <div className='overflow-y-auto no-scrollbar flex flex-col gap-1'>                
                {(isLoading ? filteredPapers.slice(0, 5) : filteredPapers).map((paper, index) => (
                  <PaperListItem
                    key={`${paper.paperId}-${index}`}
                    paper={paper}
                    onClick={() =>  handleOpenDetails(paper)}
                    isSelected={paper.paperId === selectedPaper?.paperId}
                    isLoading={isLoading}
                    onOpenDetails={() => handleOpenDetails(paper)}
                    {...listItemParams}
                  />
                ))}
              </div>
              </>
            )}
          </motion.div>

          {/* Paper Details */}
          <div ref={paperDetailsRef} className={`hidden lg:block order-3 w-[35%] ${isMobile ? mobileHeightParams : heightParams} `}>
            <AnimatePresence mode="wait">
              {selectedPaper ? (
                <PaperDetails 
                  key={`details-${selectedPaper.paperId}`} 
                  paper={selectedPaper} 
                  {...paperDetailsParams}
                />
              ) : (
                <PaperDetailsPlaceholder key="placeholder" isLoading={isLoading} />
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>

      {/* Mobile Modal for Paper Details */}
      <Modal isOpen={isMobileModalOpen} onClose={() => setIsMobileModalOpen(false)}>
        {selectedPaper && (
          <PaperDetails 
            key={`details-${selectedPaper.paperId}`}
            paper={selectedPaper} 
            isMobile={true}
            {...paperDetailsParams}
          />
        )}
      </Modal>


      <SmartSortModal
        isOpen={isSmartSortModalOpen}
        onClose={() => setIsSmartSortModalOpen(false)}
        smartSortPrompt={smartSortPrompt}
        setSmartSortPrompt={setSmartSortPrompt}
        handleSmartSortSubmit={handleSmartSortSubmit}
      />
    </motion.div>

  );
};


/* -------------------------------------------------------------------------- */
/*                                    MENUS                                   */
/* -------------------------------------------------------------------------- */

/* --------------------------------- SORTING -------------------------------- */

const SortingMenu = ({ sortBy, setSortBy, sortOrder, setSortOrder, totalPapers, isLoading }) => {
  const handleSortByChange = (option) => {
    setSortBy(option);
    posthog.capture('Sort By Changed', { sortBy: option });
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    posthog.capture('Sort Order Changed', { sortOrder: order });
  };

  return (
    <div className={`flex flex-row w-full h-fit ${isLoading ? 'opacity-50' : 'opacity-100'} transition-opacity duration-300 h-[9999]`}>
      <div className="flex lg:flex-row lg:gap-0 gap-4  justify-between items-center mb-2 w-full">
        {/* Paper Count */}
        
          <div className={`text-sm text-gray-500 ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}>
              <span className="font-semibold">{totalPapers}</span> papers
          </div>
        

        {/* actions */}
        <div className="flex items-center">
    
          {/* Sort Type */}
          <div className="flex border-x border-gray-300 px-2">
            {[['relevance', 'fire'], ['year', 'calendar'], ['citations', 'chart-network']].map(([option, icon], index) => (
              <button
                key={option}
                className={`
                  papers-display-controls-button px-2 
                  flex items-center justify-center 
                  border-b-2 w-fit
                  ${sortBy === option ? 'papers-display-controls-button-active' : ''}
                `}
                onClick={() => handleSortByChange(option)}
              >
                <Icon icon={icon} className="my-[0.18rem]" style='solid' />
              </button>
            ))}
          </div>

          {/* Sort Order */}
          <div className="flex">
            <button
              className={`papers-display-controls-button px-2  border-b-2  
                ${sortOrder === 'asc' ? 'papers-display-controls-button-active' : ''}`}
              onClick={() => handleSortOrderChange('asc')}
            >
              <Icon icon="sort-amount-up" className="" style='solid' />
            </button>
            <button
              className={`papers-display-controls-button px-2 border-b-2  ${sortOrder === 'desc' ? 'papers-display-controls-button-active' : ''}`}
              onClick={() => handleSortOrderChange('desc')}
            >
              <Icon icon="sort-amount-down" className="" style='solid' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};




/* -------------------------------------------------------------------------- */
/*                             PAPER LIST EMELENTS                            */
/* -------------------------------------------------------------------------- */

const PlaceholdersList = ({ isLoading }) => {
    const [activeIndex, setActiveIndex] = useState(0);
  
    useEffect(() => {
      let interval;
      if (isLoading) {
        interval = setInterval(() => {
          setActiveIndex((prevIndex) => (prevIndex + 1) % 5);
        }, 500);
      }
  
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [isLoading]);
  
    return (
      <div className="flex flex-col gap-2 w-full">
        {Array.from({ length: 10 }).map((_, index) => (
          <motion.div
            key={index}
            animate={{
              scale: isLoading && index === activeIndex ? 0.96 : 1,
            }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
          >
            <PaperPlaceholder />
          </motion.div>
        ))}
      </ div>
    );
  };





const PaperPlaceholder = () => (
    <div className="bg-white border border-gray-200">
      <div className="flex p-4">
        <div className="w-1/5 flex flex-col items-center justify-center">
          <div className="w-12 h-6 bg-gray-300 rounded mb-1"></div>
          <div className="w-12 h-4 mt-1 bg-gray-200 rounded mb-1"></div>
        </div>
        <div className="w-4/5">
          <div className="w-4/5 h-6 bg-gray-300 rounded mb-2"></div>
          <div className="w-1/2 ml-2 h-4 bg-gray-200 rounded"></div>
        </div>
        </div>
      </div>
    );




export default PapersDisplay;
export { PlaceholdersList }