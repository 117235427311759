import React, {useState, useEffect} from 'react';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import TextInput from '../elements/TextInput';
import StatusUpdates from '../elements/StatusUpdates';
import {AccentButton} from '../elements/Buttons';

const SmartSortButton = ({ onSmartSort }) => {
  return (
    <button
      className={`
        papers-display-controls-Button
        border border-accent bg-white text-accent 
        rounded mb-2
        hover:bg-accent hover:text-white transition-all duration-300 active:scale-95
        ${
          onSmartSort ? "opacity-100 cursor-pointer" : "opacity-0 cursor-default"
        }
      `}
      onClick={onSmartSort}
    >
      <Icon icon="fire" className="mr-2" style="solid" />
      SmartSort
    </button>
  );
};



const SmartSortModal = ({ isOpen, onClose, smartSortPrompt, setSmartSortPrompt, handleSmartSortSubmit }) => {
  const [status, setStatus] = useState({
    icon: "",
    text: "",
    color: ""
  });

  useEffect(() => {
    if (isOpen) {
      setStatus({
        icon: "",
        text: "",
        color: ""
      })
    }
  }, [isOpen])

  const handleStartSorting = () => {
    setStatus({
      icon: "fire",
      text: [
        "Thinking about your request...",
        "Sorting papers...",
        "Making sure we're not missing anything...",
        "Almost there...",
        "Sorting papers based on your prompt..."
      ],
      color: "text-accent"
    });
    handleSmartSortSubmit();
  }

  return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="z-[9999]">
          <h2 className="text-2xl font-bold mb-4 text-accent flex items-center">
            <Icon icon="fire" className="mr-2" /> SmartSort
          </h2>
          <p className="mb-1">
            <span className="font-semibold">SmartSort</span> is your intelligent research assistant, helping you pinpoint the exact papers you need.
            <span className="block highlight-border w-fit mt-2">To use it effectively:</span>
          </p>
          <ol className="list list-inside mb-4 ml-4 space-y-2 text-sm">
            <li><Icon icon="comment-alt" className="mr-2 text-accent" /> Describe your research interest as if explaining to a colleague.</li>
            <li><Icon icon="bullseye" className="mr-2 text-accent" /> Be specific about what you're looking for and what to avoid.</li>
          </ol>
          <p className="mb-1 highlight-border w-fit">Examples:</p>
          <ul className="list list-inside mb-4 space-y-2 pl-4 italic text-sm">
            <li><Icon icon="cloud-sun-rain" className="mr-2 text-accent" /> "Find papers on climate change in developing countries. Exclude papers focusing only on developed regions or purely mathematical models without experimental data."</li>
            <li><Icon icon="brain" className="mr-2 text-accent" /> "Look for papers on applying Transformers to Brain-Computer Interfaces. Focus on invasive BCIs and exclude non-invasive approaches."</li>
            <li><Icon icon="chart-network" className="mr-2 text-accent" /> "I need information on applications of differential geometry in machine learning. Prioritize papers developing ML architectures using differential geometry concepts."</li>
          </ul>
          <p className="mb-4 mt-2 w-full text-center mx-auto">
            <Icon icon="lightbulb" className="mr-2 text-accent" /> Enter your criteria below and click 'Sort' to begin!
          </p>
          <TextInput
            placeholderTexts={["Enter your sorting criteria...", "What are you looking for?", "What can we highlight for you?"]}
            onTextChange={setSmartSortPrompt}
            onEnterPress={handleStartSorting}
            buttonText="Sort"
            onButtonClick={handleStartSorting}
            buttonProps={{
              icon: "fire",
              disabled: smartSortPrompt.length === 0
            }}
            buttonComponent={AccentButton}
          />
          <StatusUpdates {...status} isVisible={status.text !== ''}/>
        </div>
      </Modal>
    );
};

export default SmartSortButton;
export { SmartSortModal };